/**
 * @file Create a date for a specific timeZone. Implements all Javascript Date
 *       functions. Replace your Date with TZDate in your project or use static
 *       method TZDate.createDate to create a new TZDate instance. Set your
 *       timeZone in TZDate.OPTIONS object. TZDate.OPTIONS = { weekday: 'long',
 *       year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',
 *       minute:'numeric', second:'numeric', millisec:'numeric',
 *       timeZone:"Antarctica/Vostok", timeZoneName:'long' } var date = new
 *       TZDate(); var date = TZDate.createDate();
 * @version 0.0.1
 * @author Ramin Danaei Boroumand
 * @copyright All rights reserved
 * @license DynamicEMR 
 * @preserve
 */

/**
 * Class to create a TZDate
 */
export default class TZDate {
	/**
	 * Takes the same parameter as JavaScript Date.
	 */
	constructor(y,m,d,h,mi,sec,milli,options){
		 var argbreak = 7;
		 for (var k in arguments){
				if (typeof arguments[k] == "object" && arguments[k] != null && !(arguments[k] instanceof Date) && !(arguments[k] instanceof TZDate) ){
					this.options = {...arguments[k]};
					arguments[k] = "undefined";
					argbreak = k;
					break;
				}
		  }
		 switch(parseInt(argbreak)){
			 case 0:
				 y=m=d=h=mi=sec=milli = undefined;
				 break;
			 case 1:
				 m=d=h=mi=sec=milli = undefined;
				 break;
			 case 2:
				 d=h=mi=sec=milli = undefined;
				 break;
			 case 3:
				 h=mi=sec=milli = undefined;
				 break;
			 case 4:
				 mi=sec=milli = undefined;
				 break;
			 case 5:
				 sec=milli = undefined;
				 break;
			 case 6:
				 milli = undefined;
				 break;
			 default:
				 break;
		 }
		 
		this.is_transition_date = false;
		this.is_transition_date_no_result = false;
		this.is_adjust_daylight_saving = false;	
		if( y && isNaN(y) ){
			var date = new Date(y);
			if( this.isLocaleDate() ){
		  	this.date = date; 
		  }
		  else{
		  	var parts = this.getDateParts(date);
		  	this.createTimeZoneDate(parts.year,parts.month-1,parts.day,parts.hour,parts.minute,parts.second,parts.millisecond,date);
		  }
		}
		else if(y && !isNaN(y) && m === undefined ){
			this.date = new Date(y);
			this._setPartsEqual();
		}
		else if(y === undefined) {
			this.date= new Date();
			this._setPartsEqual();
		}
		else if(m === undefined) {
			var newDate  = new Date(y);
			this._setPartsEqual();
		}
		else if(d === undefined){
			this.createTimeZoneDate(y,m,1,0,0,0,0);
		}
		else if(h === undefined){	
			this.createTimeZoneDate(y,m,d,0,0,0,0);
		}
		else if(mi === undefined){
			this.createTimeZoneDate(y,m,d,h,0,0,0);
		}
		else if(sec === undefined){
			this.createTimeZoneDate(y,m,d,h,mi,0,0);
		}
		else if(milli === undefined){
			this.createTimeZoneDate(y,m,d,h,mi,sec,0);
		}
		else {
			this.createTimeZoneDate(y,m,d,h,mi,sec,milli);
		}	
		return this;
	}
		
	/**
	 * Creates TZDate. Some target timeZone in daylight transition skip one hour.
	 * For Example "America/Vancouver" Mar 8, 2020 the hour 2:00 AM returns
	 * 3:00Am. So, instead, we create y,m,d,3,mi,sec,milli as it is intended for
	 * that timeZone. Creating What browser implementation would return.
	 * 
	 * @private
	 */
	createTimeZoneDate(y,m,d,h,mi,sec,milli,date){
  	this.date = new Date(y,m,d,h,mi,sec,milli);
	  if( this.isLocaleDate() || this.isInvalid()) {
	  	return this.date;
	  } 
	  if(!date){
	  	// Parameters can be negative or out of range. Normalize them first
	  	var parts = this.normalizeDate(y,m,d,h,mi,sec,milli);	  
	  	y = parts.year;
	  	m = parts.month-1;
	  	d = parts.day;
	  	h = parts.hour;
	  	mi = parts.minute;
	  	sec = parts.second;
	  	milli = parts.millisecond;
	  }
	  // Create Date if base and target date exists. Including target date exists but base date does not exists.
	  var myreturn = this.createDateOffset(y,m,d,h,mi,sec,milli,"normal");
		if(myreturn){
			return myreturn;
		}
		else{
			if(!date){
				date = new Date(y,m,d,h,mi,sec,milli);
			}
			var hour = date.getHours();
			if(h != hour){
				// Base and target date do not exist. Create What browser implementation would return for base date.
				myreturn = this.createDateOffset(y,m,d,hour,mi,sec,milli,"nobase");
			}
			else{
				myreturn = false;
			}
			if(myreturn){
				this.is_transition_date = true;
				return myreturn;
			}
			else{
				// Dates do not exists, for the base date or browser returned base date and target date. Try another time zone.	
				// This is to get the correct time offset.
				for(var k = 0 ; k<4; k++){
					this.date = new Date(y,m,d,h,mi,sec,milli);
					this.setDateOffset(this.date,mi,sec);
					// calculate in second if fractional returned.
					var targetoffset = this.getTimezoneOffset() * 60;
					var offsetstr = "+0"+k+":00";				
					var timetmp = (d*24*3600) + (h*3600) + mi*60 + sec + targetoffset + (k*3600);			
				  var d_utc = ( d_utc =  Math.floor(timetmp/(24*3600)))? d_utc :d;
					var rest_h = timetmp%(24*3600);
					var h_utc = (h_utc = Math.floor(rest_h/3600))? h_utc: h;
					var rest_m = rest_h%3600;
					var mi_utc = (mi_utc = Math.floor(rest_m/60))? mi_utc: mi;
					var sec_utc = (sec_utc = rest_m%60)?sec_utc: sec;		
					var m_utc = ("0" + (m+1)).slice(-2);
					d_utc = ("0" + d_utc).slice(-2);
					h_utc = ("0" + h_utc).slice(-2);
					mi_utc = ("0" + mi_utc).slice(-2);
					sec_utc = ("0" + sec_utc).slice(-2);	
					var milli_utc = ("00" + milli).slice(-3);			
					this.date = new Date(y+"-"+m_utc+"-"+ d_utc +"T"+h_utc+":"+mi_utc+":"+sec_utc+"."+milli_utc+""+offsetstr);	
					if(this.isInvalid()){
						// this is a browser issue to normalize date. We will ignore this issue silently.
						this.PARTS = {};
					}
					else if(this._setPartsEqual(y,m,d,h,mi,sec,milli)){
						//this.MSG["timezone"][k] = k
						return this.date;
					}		
				}
					
				// Dates do not exists for target timeZones transitions. return what target timeZone would return for this base.
				//this.MSG["nodate"]["nodate"] = "nodate";
				this.date = new Date(y,m,d,h,mi,sec,milli);
				this.setDateOffset(this.date,mi,sec);
				this._setPartsEqual(y,m,d,h,mi,sec,milli);
				if( this.getHours() > 12 && h == 0 ){
					this.setHours(this.getHours() + 2);
					this._setPartsEqual(y,m,d,h,mi,sec,milli);
				}	
				if(this.getMonth() == m && this.getHours() == 1  && h == 0  ){
					this.is_adjust_daylight_saving = true;				
				}
				else{
					this.is_transition_date_no_result = true;	
				}
				return this.date;
			} 
		}
	}
	
	/**
	 * If generated date is wrong due to timeZone shift, implementation then
	 * approximate dates until found the correct date.
	 * 
	 * @private
	 */
	createDateOffset(y,m,d,h,mi,sec,milli,label){
		for(var k = 0; k>-5; k--){
			this.date = new Date(y,m,d,h,mi,sec,milli);
			this.setDateOffset(this.date,mi+(k*60),sec);	
			if(this._setPartsEqual(y,m,d,h,mi,sec,milli)){
				//this.MSG[label][k] = k
				return this.date;
			}
		}
		for(var k = 1; k<4; k++){
			this.date = new Date(y,m,d,h,mi,sec,milli);
			this.setDateOffset(this.date,mi+(k*60),sec);	
			if(this._setPartsEqual(y,m,d,h,mi,sec,milli)){
				//this.MSG[label][k] = k			
				return this.date;
			}
		}
		return false;	
	}
	
	/**
	 * @private
	 */
	 _setPartsEqual(y,m,d,h,mi,sec,milli){
		 try{
		 this.PARTS = this.getTimeZoneDateParts(this.date,"en-US");		
		 return (
			 y == this.PARTS.year &&
			 m == this.PARTS.month-1 &&
			 d == this.PARTS.day &&
			 h == this.PARTS.hour &&
			 mi == this.PARTS.minute &&
			 sec == this.PARTS.second &&
			 milli == this.PARTS.millisecond);
		 }
		 catch(e){
				console.error("Error _setPartsEqual for this.date " + this.date +" and parts " + [y,m,d,h,mi,sec,milli] + " is "+e.message);
				console.trace(e);
				this.PARTS = {};
				return false;			 
		 }
	}
	
/**
 * get and return same parameters as Javascript Date.
 */
	toString(){
		if( this.isLocaleDate() ){
			return this.date.toString();
		}
		var options = {};
		options.hour = "2-digit";
		options.minute = "2-digit";
		options.second =  "2-digit";
		options.timeZoneName =  "short";
		options.weekday = "short";
		options.month 	= "short";
		options.day 		= "2-digit";
		options.year 		= "numeric";
		options.hourCycle = "h23";
		if(this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		var value = this.getLocaleString(options,"en-US");
		const regex = /,/gi;
		value = value.replace(regex, "");
	 	return value;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toTimeString(){
		if( this.isLocaleDate() ){
			return this.date.toTimeString();
		}
		var options = {};
		options.hour = "2-digit";
		options.minute = "2-digit";
		options.second =  "2-digit";
		options.timeZoneName =  "short";	
		options.hourCycle = "h23";
		if(this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		return this.getLocaleString(options,"en-US");
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toDateString(){
		if( this.isLocaleDate() ){
			return this.date.toDateString();
		}
		var options = {};
		options.weekday = "short";
		options.month 	= "short";
		options.day 		= "2-digit";
		options.year 		= "numeric";
		options.hourCycle = "h23";
		if(this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		var value = this.getLocaleString(options,"en-US");
		const regex = /,/gi;
		value = value.replace(regex, "");
	 	return value;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toLocaleString(locales, options){
		if(!locales){
			locales = TZDate.LOCALE;		
		}
		if(!options){
			options = this.getOPTIONS();	
		}
		else if( !options.timeZone && this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		return this.date.toLocaleString(locales, options);
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toLocaleDateString(locales, options){
		if(!locales){
			locales = TZDate.LOCALE;		
		}
		if(!options){
			options =  {};
			options.weekday = (this.getOPTIONS().weekday)? this.getOPTIONS().weekday : "short";
			options.month = (this.getOPTIONS().month)? this.getOPTIONS().month : "short";
			options.day = (this.getOPTIONS().day)? this.getOPTIONS().day : "2-digit";
			options.year = (this.getOPTIONS().year)? this.getOPTIONS().year : "numeric";
			if(this.getOPTIONS().timeZone){
				options.timeZone = this.getOPTIONS().timeZone;	
			}
		}
		else if( !options.timeZone && this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		return this.date.toLocaleDateString(locales, options);
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toLocaleTimeString(locales, options){
		if(!locales){
			locales = TZDate.LOCALE;		
		}
		if(!options){
			options = {};
			options.hour = (this.getOPTIONS().hour)? this.getOPTIONS().hour : "numeric";
			options.minute = (this.getOPTIONS().minute)? this.getOPTIONS().minute : "numeric";
			options.second = (this.getOPTIONS().second)? this.getOPTIONS().second : "numeric";
			options.millisec = (this.getOPTIONS().millisec)? this.getOPTIONS().millisec : "numeric";
			options.timeZoneName = (this.getOPTIONS().timeZoneName)? this.getOPTIONS().timeZoneName : "short";
			if(this.getOPTIONS().timeZone){
				options.timeZone = this.getOPTIONS().timeZone;	
			}
		}
		else if( !options.timeZone && this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		return this.date.toLocaleTimeString(locales, options);
	}
	
	/**
	 * @private  
	 * @param {object} options
	 * @param {string} locale
	 */
	getLocaleString(options,locale){
			return this.date.toLocaleString(locale,options);
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getDate(){
		if( this.isLocaleDate() ){
			return this.date.getDate();
		}
		return this.PARTS.day;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setDate(d){
		if( this.isLocaleDate() ){
			return this.date.setDate(d);
		}
	  if(d == undefined){
	  	return this.date.setDate(d);	
	  }
	  else{
	    this._createInstance(this.PARTS.year,
	    	this.PARTS.month -1,
	    	d,
	    	this.PARTS.hour,
	    	this.PARTS.minute,
	    	this.PARTS.second,
	    	this.PARTS.millisecond);
			return this.getTime();
	  }
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getDay(){
		if( this.isLocaleDate() ){
			return this.date.getDay();
		}
		var options = {};
		if(this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		options.weekday = "long";
		return parseInt(TZDate.getWeekdayNumber(this.getLocaleString(options,"en-US")))  ;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getFullYear(){
		if( this.isLocaleDate() ){
			return this.date.getFullYear();
		}
		return this.PARTS.year;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setFullYear(y,m,d){	
			if(y == undefined){
				return this.date.setFullYear(y);
			}
		  else{
		  	m = (m != undefined)? m: this.getMonth() ;
		  	d = (d != undefined)? d: this.getDate();
				if( this.isLocaleDate() ){
					return this.date.setFullYear(y,m,d);
				}
		   this._createInstance(y,m,d,
		    	this.PARTS.hour,
		    	this.PARTS.minute,
		    	this.PARTS.second,
		    	this.PARTS.millisecond);
				return this.getTime();
		  }
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getYear(){
		return this.getFullYear();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setYear(y,m,d){
		return this.setFullYear(y,m,d);
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getHours(){
		if( this.isLocaleDate() ){
			return this.date.getHours();
		}
		return this.PARTS.hour;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setHours(h,mi,sec,milli){
		if(h == undefined){
			return this.date.setHours(h);
		}
	  else{
	    mi = (mi != undefined)? mi: this.getMinutes();
	    sec= (sec != undefined)? sec: this.getSeconds();
	    milli = (milli != undefined)? milli: this.getMilliseconds();
	  	if( this.isLocaleDate() ){
	  			return this.date.setHours(h,mi,sec,milli);
	  	}	
	    this._createInstance(this.PARTS.year,this.PARTS.month-1,this.PARTS.day,h,mi,sec,milli);
			return this.getTime();
	  }
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getMilliseconds(){
		return this.date.getMilliseconds();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setMilliseconds(milli){
  	if( this.isLocaleDate() ){
			return this.date.setMilliseconds(milli);
  	}	
		if(milli == undefined || milli == null){
			return this.date.setMilliseconds(milli);
		}
	  else{
	    this._createInstance(this.PARTS.year,this.PARTS.month-1,this.PARTS.day,this.PARTS.hour,this.PARTS.minute,this.PARTS.second,milli);
			return this.getTime();
	  }
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getMinutes(){
		if( this.isLocaleDate() ){
			return this.date.getMinutes();
		}
		return this.PARTS.minute;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setMinutes(mi,sec,milli){	
		if(mi == undefined){
	  	return this.date.setMinutes(mi);
	  }
	  else{
	    sec = (sec != undefined)? sec: this.getSeconds();
	    milli = (milli != undefined)? milli: this.getMilliseconds();
			if( this.isLocaleDate() ){
				return this.date.setMinutes(mi,sec,milli);
			}
	    this._createInstance(this.PARTS.year,this.PARTS.month-1,this.PARTS.day,this.PARTS.hour,mi,sec,milli);
			return this.getTime();
	  }
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getMonth(){
		if( this.isLocaleDate() ){
			return this.date.getMonth();
		}
		return this.PARTS.month - 1;
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setMonth(m,d){
		if(m == undefined){
	  	return this.date.setMonth(m);
	  }
	  else{
	  	d = (d != undefined)? d : this.getDate();
			if( this.isLocaleDate() ){
				return this.date.setMonth(m,d);
			}
	    this._createInstance(this.PARTS.year,m,d,this.PARTS.hour,this.PARTS.minute,this.PARTS.second,this.PARTS.millisecond);
			return this.getTime();
	  }
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getSeconds(){
		if( this.isLocaleDate() ){
			return this.date.getSeconds();
		}
		return this.PARTS.second;
	} 
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setSeconds(sec,milli){
	  if(sec == undefined){
	  	return this.date.setSeconds(sec);
	  }
	  else{
	    milli = (milli != undefined)?milli:this.getMilliseconds();
	  	if( this.isLocaleDate() ){
	  		return this.date.setSeconds(sec,milli);
	  	}	
	    this._createInstance(this.PARTS.year,this.PARTS.month -1,this.PARTS.day,this.PARTS.hour,this.PARTS.minute,sec,milli);
			return this.getTime();
	  }
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getTime(){
		if(this.date){
			return this.date.getTime();
		}
		else{
		   return Number.NaN;
		}
	} 
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setTime(timeValue){
		return this.date.setTime(timeValue);
	} 
	
	/**
	 * get and return same parameters as Javascript Date.
	 * @param {boolean} locale - true returns the locale timeZone offset. Otherwise TZDate timeZone offset 
	 * @return {number} 
	 */
	getTimezoneOffset(locale){
		if(locale){
			return this.date.getTimezoneOffset();
		}
		else{
			var offset = this.getGMT(this.date);
			if(offset.length == 1){
				return -1*offset[0];
			}
			else{
				return -1 * (offset[0] + (offset[1]/60));
			}			
		}
	} 
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCDate(){
		return this.date.getUTCDate();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setUTCDate(d){
		this.date.setUTCDate(d);
		return this.getTime();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCDay(){
		return this.date.getUTCDay();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCFullYear(){
		return this.date.getUTCFullYear();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setUTCFullYear(y,m,d){
			if(m == undefined || m == null){
				this.date.setUTCFullYear(y);
			}
			else if(d == undefined || d == null){
				this.date.setUTCFullYear(y,m);
			}
			else {
				this.date.setUTCFullYear(y,m,d);
			}
			return this.getTime();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCHours(){
		return this.date.getUTCHours();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setUTCHours(h,mi,sec,milli){
			if(mi == undefined || mi == null){
				this.date.setUTCHours(h);
			}
			else if(sec == undefined || sec == null){
				this.date.setUTCHours(h,mi);
			}
			else if(milli == undefined || milli == null){
				this.date.setUTCHours(h,mi,sec);
			}
			else {
				this.date.setUTCHours(h,mi,sec,milli);
			}
			return this.getTime();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCMilliseconds(){
		return this.date.getUTCMilliseconds();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setUTCMilliseconds(milli){
			this.date.setUTCMilliseconds(milli);
			return this.getTime();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCMinutes(){
		return this.date.getUTCMinutes();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setUTCMinutes(mi,sec,milli){
			if(sec == undefined || sec == null){
				this.date.setUTCMinutes(mi);
			}
			else if(milli == undefined || milli == null){
				this.date.setUTCMinutes(mi,sec);
			}
			else {
				this.date.setUTCMinutes(mi,sec,milli);
			}
			return this.getTime();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCMonth(){
		return this.date.getUTCMonth();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setUTCMonth(m,d){
			if(d == undefined || d == null){
				this.date.setUTCMonth(m);
			}
			else {
				this.date.setUTCMonth(m,d);
			}
			return this.getTime();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	getUTCSeconds(){
		return this.date.getUTCSeconds();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	setUTCSeconds(s,milli){
			if(milli == undefined || mill == null){
				this.date.setUTCSeconds(s);
			}
			else {
				this.date.setUTCSeconds(s,milli);
			}
			return this.getTime();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toISOString(){
		return this.date.toISOString();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toGMTString(){
		return this.date.toGMTString();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toJSON(){
		return this.date.toJSON();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	toUTCString(){
		return this.date.toUTCString();
	}
	
	/**
	 * get and return same parameters as Javascript Date.
	 */
	valueOf(){
		return this.date.valueOf();
	}
	
	/**
	 * 
	 */
	isNull(val){
		retrn (val == undefined || val == null);
	}
	
	/**
   * @return {Boolean}
   */
	isInvalid(){
		return (!this.date || isNaN(this.date.getTime()));
	}
	
  _createInstance(y,m,d,h,mi,sec,milli) {
    var date =  new TZDate(y,m,d,h,mi,sec,milli,this.options);
    for (var k in date){
    	this[k] = date[k];
    }
  }
	
	isLocaleDate(){
	  return (TZDate.TZ == this.getOPTIONS().timeZone  || !this.getOPTIONS().timeZone  );
	}
	
	static get TZ(){
		if(!this.tz){
			this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
		}
		return this.tz;
	}
	
	/**
	 * Static function to create a new TZDate object.
	 * takes the exact parameters as a Javascript Date.
	 * TZDate.createDate()
	 * @return {TZDate} 
	 */
  static createDate(y,m,d,h,mi,sec,milli) {
    return new this(y,m,d,h,mi,sec,milli);
  }
  
	/**
	 * get and return same parameters as Javascript Date.
	 */
  static UTC(y,m,d,h,mi,sec,milli) {
  	var date = Date.UTC(y,m,d,h,mi,sec,milli);
  	var tzdate = new TZDate(date);
  	return tzdate.getTime();
  }
  
	/**
	 * get and return same parameters as Javascript Date.
	 */
  static now() {
    var date = new TZDate();
    return date.getTime();
  }
  
	/**
	 * get and return same parameters as Javascript Date.
	 */
  static parse(y) {
  	var parsed = Date.parse(y);
  	if(TZDate.TZ == TZDate.OPTIONS.timeZone  || !TZDate.OPTIONS.timeZone  ){
  		return parsed;
  	}	
  	else if(isNaN(parsed) || !parsed){
  		return parsed;
  	}
  	else{
    	var date = new TZDate(y);
    	return date.getTime(); 		
  	}
  }
  
  /**
   * global options, default is set to 
   *  { 
   * 	weekday: 'long', 
   * 	year: 'numeric', 
   * 	month: 'long', 
   * 	day: 'numeric', 
   * 	hour:'numeric', 
   * 	minute:'numeric',
   * 	second:'numeric',
   * 	millisec:'numeric',
   * 	timeZoneName:'long'
   * }  
   */
  static get OPTIONS(){
  	if(!this.options){
  		return  { 
  							weekday: "long", 
  							year: "numeric", 
  							month: "long", 
  							day: "numeric", 
  							hour:"numeric", 
  							minute:"numeric",
  							second:"numeric",
  							millisec:"numeric",
  							timeZoneName:"long",
  							timeZone:TZDate.TZ
  						};
  	}
  	else{
 	 		return this.options;
  	}
  } 
  
  /**
   * set global options object, TZDate.OPTIONS = {...}  
   */
  static set OPTIONS(options){
  	this.options = options;
  	this.timeZoneFormatter = null;
  	this.getTimeZoneFormatter();
  } 
  
  
  /**
   * default is set to 
   *  { 
   * 	weekday: 'long', 
   * 	year: 'numeric', 
   * 	month: 'long', 
   * 	day: 'numeric', 
   * 	hour:'numeric', 
   * 	minute:'numeric',
   * 	second:'numeric',
   * 	millisec:'numeric',
   * 	timeZoneName:'long'
   * }  
   */
  get OPTIONS(){
 	 		return this.options;
  } 
  
  /**
   *  
   */
  set OPTIONS(options){
  	this.options = options;  	
  	this.timeZoneFormatter = null;
  	this.getTimeZoneFormatter();
  } 
  
  
  getOPTIONS(){
  	var options = this.options;
  	if(!options){
  		options = TZDate.OPTIONS;
  	}
  	return options;
  }
  
  /**
   * global locale, default is set to "en-US"
   */
  static get LOCALE(){
  	if(!this.locale){
  		return "en-US";
  	}
  	else{
 	 		return this.locale;
  	}
  } 
  
  /**
   * set global locale string, TZDate.LOCALE = "en-US"  
   */
  static set LOCALE(locale){
  	this.locale = locale;
  } 
  
  /**
   * global locale, default is set to "en-US"
   */
 get LOCALE(){
  	if(!this.locale){
  		return "en-US";
  	}
  	else{
 	 		return this.locale;
  	}
  } 
  
  /**
   * set locale string 
   */
  set LOCALE(locale){
  	this.locale = locale;
  } 
  
  /**
   * @private
   */
  getGMTWLocale(date,locale){
	  var options = {};
	  options.minute = "numeric";
	      var parts = this.getTimeZoneDateParts(date,locale);
  			var dtstr =  parts["timeZoneName"];
    		var isgmt = dtstr.includes("GMT");
    		if(isgmt){
    			var reg_numbers = /\b[-+]?\d+\b/g;
    			var arr = dtstr.match(reg_numbers);
    			if(!arr || arr.length == 0){
    				return [0];
    			}
    			else if(arr.length == 1){
    				return [parseInt(arr[0])*60];
    			}
    			else if(arr.length == 2){
    				var myhours =  parseInt(arr[0])*60;
   			 		var myminutes = parseInt(arr[1]); 
   			 		if(myhours <0 ){
   			 			return [myhours - myminutes];
   			 		}
   			 		else{
   			 			return [myhours + myminutes];
   			 		}
    			}
    			else if(arr.length == 3){
    				var myhours =  parseInt(arr[0])*60;
   			 		var myminutes = parseInt(arr[1]); 
   			 	  var myseconds = parseInt(arr[2]);
   			 		if(myhours <0 ){
   			 			return [myhours - myminutes, -myseconds];
   			 		}
   			 		else{
   			 			return [myhours + myminutes, myseconds];
   			 		}
    			}
    			return [Number.NaN];
    		}
    		else{
      		var isutc = dtstr.includes("UTC");
      		if(isutc){
      			return [0];
      		}
    			return [Number.NaN];
    		}
  }

	/*
	*  javascript returns oposite timezoneoffset, where as php time('Z') returns the correct offset.
	*  Javascript Doc: The offset is positive if the local timezone is behind UTC, and negative if it is ahead.
	*  PHP Doc: Timezone offset in seconds. The offset for timezones west of UTC is always negative, and for those east of UTC is always positive.
	*/
  
  /**
   * returns timeZone offset in minutes for a Javascript date. It uses the timeZone specified in TZDate.OPTIONS.
   * The offset for timezones west of UTC is always negative, and for those east of UTC is always positive.
   * This is  different from Javascript Date getTimezoneOffset. 
   * where The offset is positive if the local timezone is behind UTC, and negative if it is ahead.
   * @param {Date} date
   * @return {number}
   */
  getGMT(date){
  	var locales = ["en-GB","en-US"];
  	var _return = Number.NaN;
  	for(var k in locales){
  		_return = this.getGMTWLocale(date,locales[k]);
  		if(!isNaN(_return[0])){
  			break;
  		}
  	}
  	return _return;
  }

  
  /**
   * returns the offSet minutes to reconstruct target timeZone date
   * @private
   */
  setDateOffset(date,minutes,sec){
  	var gmt = this.getGMT(date);
  	var offset = (minutes - date.getTimezoneOffset() -  gmt[0]);
  	date.setMinutes(offset);
  	if(gmt.length == 2){
  		date.setSeconds(sec - ((this._getMinutes() - minutes )*60) - gmt[1]);
  	}
  	return date;
  }
  
  /**
   * @private
   */
	_getMinutes(){
		var options = {};
		if(this.getOPTIONS().timeZone){
			options.timeZone = this.getOPTIONS().timeZone;	
		}
		options.minute = "numeric";
		return parseInt(this.getLocaleString(options))  ;
	}
  
	/**
	 * Formats a Javascript date to it's parts. 
	 * @param {Date} date - a Javascript Date object
	 * @param {Boolean} utc - If true uses the UTC, otherwise locale timeZone is used.
	 * @return {object} - {year: Number, month: Number, day: Number, hour: Number, minute: Number,second: Number, millisecond:Number}
	 */
  getTimeZoneDateParts(date,locale){
		try{
		var formatted = null;
		formatted = this.getINTLFormatter()[locale].formatToParts(date);
		var _return = {};
		for (var k in formatted){
			var parsed = parseInt(formatted[k]["value"]);
			_return[formatted[k]["type"]] = !isNaN(parsed)?parsed : formatted[k]["value"] ;
		}
		_return["millisecond"] = date.getMilliseconds();
		return _return;
		}
		catch(e){
			return {};
		}
	}
   
	/**
	 * Formats a Javascript date to it's parts. 
	 * @param {Date} date - a Javascript Date object
	 * @param {Boolean} utc - If true uses the UTC, otherwise locale timeZone is used.
	 * @return {object} - {year: Number, month: Number, day: Number, hour: Number, minute: Number,second: Number, millisecond:Number}
	 */
  getDateParts(date,utc){
		var formatted = null;
		if(utc){
			formatted = TZDate.getUTCFormatter().formatToParts(date);
		}
		else{
			formatted = TZDate.getFormatter().formatToParts(date);
		}
		var _return = {};
		for (var k in formatted){
			_return[formatted[k]["type"]] = parseInt(formatted[k]["value"]);
		}
		_return["millisecond"] = date.getMilliseconds();
		return _return;
	}
  
  /*
   * 
   */
  toParts(formatter){
		formatter = (formatter)? formatter: new Intl.DateTimeFormat(this.LOCALE,this.getOPTIONS());
		var formatted = formatter.formatToParts(this.date);
		var _return = {};
		for (var k in formatted){
			_return[formatted[k]["type"]] = formatted[k]["value"];
		}
		_return["millisecond"] = this.date.getMilliseconds();
		return _return;
	}
  
  getParts(){
  	if( this.isLocaleDate() ){
 		 this.PARTS = this.getTimeZoneDateParts(this.date,"en-US");		
  	}
    return this.PARTS;
  }
	
	/**
	 * @return {DateTimeFormat}
	 */
	static getFormatter(){
		if(!this.formatter){
			var options = {
			  year: "numeric",
			  month: "numeric",
			  day: "numeric",
			  hour: "numeric",
			  minute: "numeric",
			  second: "numeric",
			  hourCycle: "h23"
			};
			this.formatter = new Intl.DateTimeFormat("en-US",options );
		}
		return this.formatter;
	}
	
	/**
	 * @return {DateTimeFormat}
	 */
	static getUTCFormatter(){
		if(!this.utcformatter){
			var options = {
			  year: "numeric",
			  month: "numeric",
			  day: "numeric",
			  hour: "numeric",
			  minute: "numeric",
			  second: "numeric",
			  timeZone:"UTC",
			  hourCycle: "h23"
			};
			this.utcformatter = new Intl.DateTimeFormat("en-US",options );
		}
		return this.utcformatter;
	}
	
	/**
	 * Returns a Intl formatter based on OPTIONS timeZone
	 * @return {DateTimeFormat}
	 */
  static getTimeZoneFormatter(){
		if(!this.timeZoneFormatter){
			var options = {
			  year: "numeric",
			  month: "numeric",
			  day: "numeric",
			  hour: "numeric",
			  minute: "numeric",
			  second: "numeric",
			  timeZoneName : "short",
			  hourCycle: "h23"
			};
			if(this.OPTIONS.timeZone){
				options.timeZone = this.OPTIONS.timeZone;	
			}
			this.timeZoneFormatter = {
				"en-US":new Intl.DateTimeFormat("en-US",options ),
				"en-GB":new Intl.DateTimeFormat("en-GB",options ),
			};
		}	
		return this.timeZoneFormatter;
	}
	
	/**
	 * Returns a Intl formatter based on OPTIONS timeZone
	 * @return {DateTimeFormat}
	 */
	getTimeZoneFormatter(){
		if(!this.timeZoneFormatter){
			var options = {
			  year: "numeric",
			  month: "numeric",
			  day: "numeric",
			  hour: "numeric",
			  minute: "numeric",
			  second: "numeric",
			  timeZoneName : "short",
			  hourCycle: "h23"
			};
			if(this.getOPTIONS().timeZone){
				options.timeZone = this.getOPTIONS().timeZone;	
			}
			this.timeZoneFormatter = {
				"en-US":new Intl.DateTimeFormat("en-US",options ),
				"en-GB":new Intl.DateTimeFormat("en-GB",options ),
			};
		}
		
		return this.timeZoneFormatter;
	}
	
	getINTLFormatter(){
		var formatter = this.getTimeZoneFormatter();
		if(!formatter){
			formatter = TZDate.getTimeZoneFormatter();
		}
		return formatter;
	}
	
	/**
	 * normalizes passed parameter. 
	 * @return {object} - {year: Number, month: Number, day: Number, hour: Number, minute: Number,second: Number, millisecond:Number}
	 */
  normalizeDate(y,m,d,h,mi,sec,milli){
    var time = Date.UTC(y,m,d,h,mi,sec,milli);
    return this.getDateParts(new Date(time),true);
  }
  
	
  /**
   * locale must be en-US
   * @private
   * @param {string}
   * @return {number}
   */
  static getWeekdayNumber(str){
  	var weekday=new Array(7);
  	weekday["Sunday"]=0;
  	weekday["Monday"]=1;
  	weekday["Tuesday"]=2;
  	weekday["Wednesday"]=3;
  	weekday["Thursday"]=4;
  	weekday["Friday"]=5;
  	weekday["Saturday"]=6;
  	return weekday[str];
  }
  
  get MSG(){
  	if(!this.msg){
  		this.msg = {};
  		this.msg["normal"] = {};
  		this.msg["nobase"] = {};
  		this.msg["timezone"] = {};
  		this.msg["nodate"] = {};		
  	}
  	try{
  		var opt = this.getOPTIONS().timeZone;
  		var optintl = TZDate.timeZoneFormatter.resolvedOptions();
  		if( opt && optintl && opt !=  optintl.timeZone ){
  			this.msg[opt]={"intl":optintl.timeZone};
  		}
  	}
  	catch(e){	
  	}
  	return this.msg;
  } 
}



