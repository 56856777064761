"use strict";
import React, { Component } from "react";
import DisplayScreen from "./DisplayScreen";
import InputAction from "./InputAction.js";
import { PlusCircleIcon} from "@primer/octicons-react";
import AlertArea from "./AlertArea.js";
import MainButton from "./MainButton.js";
import { CSSTransition, TransitionGroup} from "react-transition-group";

export default class AddTask extends Component {
  constructor(props) {
    super(props);
    this.state ={
    	task_title:"",
    	task_desc:"",
    	task_urgent:true,
    	task_important:true, 
    	task_delegate:false,
    	task_delegate_to:"",
    	task_items:[],
    	item:"",
    	message:null,
    	alert_type:"alert-success",
    	time_stamp:new Date().getTime()
    }
  }
  
  componentDidMount() {
  	try{
  		if(this.props.getTask){
  			var task_obj = this.props.getTask();
  			if(task_obj){
  				task_obj.message = null;
  				this.setState(task_obj);
  			}
  		}
  	}
  	catch(error){ 
			 this.handleError(error);
  	}
  }
  
  componentDidUpdate(prevProps, prevState){	
  }

	onDone(done){
		try{
			if(done){
				this.props.on_done(this.state,(msg,type)=>{this.onComplete(msg,type)});
			}
			else{
				this.props.on_done(false,(msg,type)=>{this.onComplete(msg,type)});
			}			
		}
		catch(error){
			 this.handleError(error);			
		}
	}
	
	onComplete(msg,type){
		if(type == "alert-success"){
			msg = "Saved successfully. Add another task.";
	    this.setState({
	    	task_title:"",
	    	task_desc:"",
	    	task_urgent:true,
	    	task_important:true, 
	    	task_delegate:false,
	    	task_delegate_to:"",
	    	task_items:[],
	    	item:"",
	    	message:msg,
	    	alert_type:type
	    });
		}
		else{
			this.setState({alert_type:type, message:msg});
		}
	}
	
	removeItem(index){
		var task_items = [...this.state.task_items]
		task_items.splice(index,1)
		this.setState({task_items:task_items})
	}
	
	addItem(){
		try{
	  	if( this.state.item && this.state.item.trim() ){
	  		var task_items = [...this.state.task_items]
	  		task_items.push({task_item:this.state.item,task_done:false})
	  		this.setState({task_items:task_items,item:""})  	
	  	}
	  	else{
	  		this.setState({message:"Item is empty",alert_type:"alert-warning"});
	  	}			
		}
		catch(error){
			 this.handleError(error);			
		}
	}
	
	removeAlert(){
		this.setState({message:null,alert_type:"alert-success"})
	}
	
  onAddItemReturn(event){ 	
    if (event.key === "Enter") {
    	this.addItem();
    }		
  }
  
	handleError(error){
		console.log(error);
		this.setState({message:error.message,alert_type:"alert-danger"})
	}
	
  render() {
	  return( 
	  	<DisplayScreen key_val="start_alarm_alarmlist" display="ds-modal-content ds-modal-fill p-3"  >		  
		  	<div className="d-flex flex-row justify-content-around align-items-center x-scroll h-13">
		  			<div className="display-6 text-primary interactive-box" onClick={()=>{this.onDone(false);}}>Cancel</div>
		  			<div className="display-6 text-primary interactive-box" onClick={()=>{this.onDone(true);}} >Save</div>	
		  			<div className="position-absolute w-100 " >
			        <AlertArea alert_type={this.state.alert_type} label={this.state.message} isClicked={()=>{this.removeAlert()}} />
			      </div>
			  </div>
		  	<div className="d-flex flex-column justify-content-between align-items-center x-scroll h-87 position-relative">
		  			<div className="d-flex-row-centered">
		  				<div className="d-flex-column-scrolled  w-100 p-1">	
			  			    <div className="display-10  text-center mb-2" >Add Task based on Importance and Urgency</div>		
		    					<div className="custom-control custom-switch mt-3 mt-lg-4">
  									<input checked={this.state.task_important} type="checkbox" className="custom-control-input" id="task_important" onChange={ (event)=>{ this.setState({task_important:event.target.checked}); } } />
  									<label className="custom-control-label" htmlFor="task_important">This task is important</label>
  								</div>	
			  			  	<div className="custom-control custom-switch mt-3 mt-lg-4">
    								<input checked={this.state.task_urgent} type="checkbox" className="custom-control-input" id="task_urgent" onChange={ (event)=>{ this.setState({task_urgent:event.target.checked}) } } />
    								<label className="custom-control-label" htmlFor="task_urgent">This task is urgent</label>
    							</div>
		    					{
		    						this.state.task_urgent && !this.state.task_important &&
		    						 <div className="mt-3 mt-lg-4">
		    						   <div className="text-info">
		    						   	This task is not important, however requires your attention. Delegate this task if possible. 
		    						   	If parts of this task are important then split it in to multiple tasks with different categories.	    						   	
		    						   </div>
		  			    			 <div className="custom-control custom-switch mt-3 mt-lg-4">
			    							 <input checked={this.state.task_delegate} type="checkbox" className="custom-control-input" id="task_delegate" onChange={ (event)=>{ this.setState({task_delegate:event.target.checked}); } }  />
			    							 <label className="custom-control-label" htmlFor="task_delegate">Delegate this task</label>
			    						 </div>
		    							 {this.state.task_delegate &&
		   			  			    	<div>
				  			  					<label htmlFor="task_delegate_to" className="form-label">Delegate to</label>
				  			  					<input type="text" className="form-control display-10 border-ridge w-100" id="task_delegate_to"  value={this.state.task_delegate_to} onInput={ (event)=>{ this.setState({task_delegate_to:event.target.value}); } }  />
				  			  				</div>
		    								 }
		    						 </div>
		    					}
		    					
			  			    <div className="mt-3 mt-lg-4">
		  			  			<label htmlFor="task_title" className="form-label">Task</label>
		  			  			<input type="text" className="form-control display-10 border-ridge w-100" id="task_title" placeholder="task name"  value={this.state.task_title} onInput={ (event)=>{ this.setState({task_title:event.target.value}); } } />
		  			  	  </div>

			  			  	<div className="mt-3 mt-lg-4">
			  			  		<label htmlFor="task_desc" className="form-label">Description</label>
			  			  		<textarea className="form-control display-10 border-ridge w-100" id="task_desc" rows="3" value={this.state.task_desc}  onInput={ (event)=>{ this.setState({task_desc:event.target.value}); } }></textarea>
			  			  	</div>
			  			  	
			  			  	<div className="mt-3 mt-lg-4">
		  			  			<label className="form-label">Items</label>
		  							<div className="input-group">
		  								<div className="input-group-prepend">
		  									<MainButton isClicked={()=>{this.addItem();}}  isActive={false}  attributes={{"className":"btn btn-outline-primary"}} title="Add Item" />
		  								</div>
		  								  <input className="form-control text-primary" type="text" value={this.state.item}  placeholder="Add item if required" autoComplete="off"  
		  								  	onKeyPress={(event)=>{this.onAddItemReturn(event)}}
		  								  	onInput={ (event)=>{ this.setState({item:event.target.value}); } } />
		  						  </div>
		  			  		</div>
			  			  	
			  					<ol className="mt-3 mt-lg-4" type="1">
			  	    		{this.state.task_items.map((item,index) => { 
			  	    			return(
			  	    				<li key={index}>
			  	      				<div className="alert alert-light alert-dismissible" role="alert">
			  	      				{item.task_item} 
			  	  						<button ref={this.btnref}  type="button" className="close alert alert-danger"  onClick={()=>{this.removeItem(index)}} >
			  	  							<span  aria-hidden="true">&times;</span>
			  	  						</button>
			  	  						</div>
			  	    				</li>
			  	    			);
			  	    		}
			  	    	 )}
			  	    		</ol>
							</div>
						</div>
					</div>
	    </DisplayScreen>
	  );   
  }
  
}