"use strict";
import React, { Component } from "react";
import { CSSTransition, TransitionGroup} from "react-transition-group";

export default class AlertArea extends Component {
  constructor(props) {
    super(props);
    this.btnref = React.createRef();
  }
  
  componentDidUpdate(prevProps, prevState) {
  	try{
  		if(this.props.label){
  			clearTimeout(this.timeout)
  			this.timeout = setTimeout(()=>{
  				try{
  					this.btnref.current.click();
  				}
  				catch(e){
  				}
  			},window.alert_area_delay);
  		}
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  handleClick() {
  	  clearTimeout(this.timeout);
    	this.props.isClicked();	
  }
 
  render() {
  	var alertarea = null; 
    var type = this.props.alert_type? this.props.alert_type: "alert-warning";
    var class_name ="alert alert-dismissible " + type;
  	if(this.props.label){
  		alertarea =   
				<CSSTransition key="alertarea_timezone" timeout={500} classNames="area">
  				<div className={class_name} role="alert" >
  				  <span>
							{this.props.label} 
  				  </span>
						<button ref={this.btnref} type="button" className="close" onClick={()=>{this.handleClick()}} >
							<span  aria-hidden="true">&times;</span>
						</button>
					</div>
				</CSSTransition>;	  
  	}
    return <TransitionGroup> {alertarea} </TransitionGroup> ;
  }
  
}