"use strict";
import React, { Component } from "react";
import ErrorBoundary from "./ErrorBoundary.js";
import {UtilClock} from "../lib/util.js";

export default class WorldClock extends Component {
	
  constructor(props) {
    super(props);
    this.util_clock = new UtilClock();
		var tza = this.props.timezone.split("/");
		this.state={tz:tza[tza.length-1]}
  }
  
  handleClick() {
  	this.props.isClicked(this.props.timezone,this.props.index);	
  }
 
  render() {
		  let {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,this.props.parts);
			var sec = clock.second? "display-10 superscript float-right" : false;
			var per = (clock.dayperiod || clock.dayPeriod) ? "display-10 subscript float-right" : false;
			var left = (!per)?"unset":null;
    	return (	
    		<ErrorBoundary>
					<div className="alert alert-light alert-dismissible d-lg-flex flex-row justify-content-between flex-wrap align-items-center" role="alert">
		  			<div className="display-6 d-flex">
		  				<span>{clock.hour}:{clock.minute}</span> 
	  				  <div className="d-flex flex-column justify-content-center display-6-subsup" >
		  					<span className="align-self-center" >{clock.second}&nbsp;</span>
		  					<span className="align-self-center">{clock.dayPeriod}&nbsp;</span>
		  				</div>
		  			</div>
		  			<div>{clock.timeZoneName} - {this.state.tz}</div>
		  			<div>{clock.weekday}, {clock.month}  {clock.day}, {clock.year}</div>
		  			<button type="button" className="close text-danger" aria-label="Close" onClick={()=>{this.handleClick()}}>
		  				<span aria-hidden="true">&times;</span>
		  			</button>
		  	  </div>
        </ErrorBoundary>
    	);
  }
  
}