"use strict";
import React, { Component } from "react";
import {UtilPermission,alarm_tone} from "../lib/utilPermission.js";
import DisplayScreen from "./DisplayScreen";

export default class AlarmTone extends Component {
  constructor(props) {
    super(props);
    this.state = {default_alarm_tone:alarm_tone[Object.keys(alarm_tone)[0]]};
    this.util_permission = new UtilPermission(); 
  }
  
  componentDidMount(){
		this.setState({default_alarm_tone:this.props.default_alarm_tone()});
  }
 
  setDefault(event){
  	var default_alarm_tone = {...alarm_tone[event.target.value]};
		this.setState({default_alarm_tone:default_alarm_tone},()=>{
	    this.util_permission.requestPlay(default_alarm_tone.src); 

		});
  }
		
	onDone(done){
		this.util_permission.requestPause();
		if(done){
			this.props.on_done(this.state.default_alarm_tone);
		}
		else{
			this.props.on_done(false);
		}
	}
  
  getAlarmObject(){
	  return( 
	  	<DisplayScreen key_val="start_alarm_alarmlist" display="ds-modal-content ds-modal-fill p-3"  >		  
		  		<div className="d-flex flex-row justify-content-around align-items-center x-scroll h-13">
		  			<div className="display-6 text-primary interactive-box" onClick={()=>{this.onDone(false);}}>Cancel</div>
		  			<div className="display-6 text-primary interactive-box" onClick={()=>{this.onDone(true);}} >Done</div>		  				
				</div>
		  		<div className="d-flex flex-column justify-content-between align-items-center x-scroll h-87 position-relative">
		  			<div className="d-flex-row-centered">
		  				<div className="d-flex-column-scrolled  w-100 p-1">	
			  			  <div className="display-8 mb-2" >Alarm Tone</div>
								{this.getAlarmList()}
							</div>
						</div>
					</div>
	    </DisplayScreen>
	  );
  }
  
  getAlarmList(){
  	var element = [];
    	for (var k in alarm_tone){
      		element.push(
      		<div className="form-check mb-2" key={k} >
      			<input className="form-check-input" type="radio" name="alarmToneChoice" id={k} value={k} onChange={(event)=>{this.setDefault(event);}} checked={this.state.default_alarm_tone.name==k} /> 
      		  <label className="form-check-label text-nowrap" htmlFor={k}>{k}</label>
      		</div>		  
      		);	  	
    	}  		
  	return element;
  }
 
  render() {
    return  this.getAlarmObject();
  }
  
}