"use strict";
import React, { Component } from "react";
import SearchTimezone from "./SearchTimezone.js";
import TimePicker from "./TimePicker.js";
import WorldAlarm from "./WorldAlarm.js";
import ErrorBoundary from "./ErrorBoundary.js";
import {UtilClock,UtilState} from "../lib/util.js";
import {UtilPermission} from "../lib/utilPermission.js";
import AlarmTone from "./AlarmTone";
import PropTypes from 'prop-types';

class AddAlarm extends Component {
  constructor(props){
    super(props);
    this.day_class = "display-7 border-ridge text-center interactive-box curved-shadow-simple min-width-2-5 ";
    this.day_active_class = "display-7 border-ridge text-center interactive-box curved-shadow-simple min-width-2-5 interactive-active";
    this.week_day_list = [0,1,2,3,4,5,6]; 
    this.util_clock = new UtilClock();
    this.util_state =  new UtilState();
    this.util_permission = new UtilPermission(); 
    this.state = {day_label:[]}
    
    for (var k in this.week_day_list ){
    	this.state.day_label[k] = this.util_clock.getWeekDay(k) 	
    }
    
  }
  
  componentDidUpdate(prevProps, prevState){	
    var props_return =  this.util_state.stateIsChanged(prevProps.editWorldAlarm.options , this.props.editWorldAlarm.options, false);
    if(props_return || (prevProps.editWorldAlarm.locale != this.props.editWorldAlarm.locale)){ 	
    	var editWorldAlarm = this.deapCloneEdit();
    	editWorldAlarm.parts.millisecond = (parseInt(editWorldAlarm.parts.millisecond) + 1) % 1000;  	
    	this.props.setProp("editWorldAlarm",editWorldAlarm);
  	}	
  }
  
  setDefaultAlarmTone(){
    this.props.mountModal(<AlarmTone default_alarm_tone={()=>this.props.editWorldAlarm.default_alarm_tone} on_done={(at)=>{this.onDone(at);}} />); 			
  }
  
  onDone(at){
  	if(at){
    	var editWorldAlarm = this.deapCloneEdit();
    	editWorldAlarm.default_alarm_tone = {...at};
    	this.props.setProp("editWorldAlarm",editWorldAlarm);
  	}
  	this.props.unMountModal();	
  }
  
  deapCloneEdit(){
  	/*
  	var e = this.props.editWorldAlarm;
  	var c = {...e,options:{...e.options},parts:{...e.parts},repeat:{...e.repeat},default_alarm_tone:{...e.default_alarm_tone}};
  	return c;
  	*/
  	return this.util_state.deapClone(this.props.editWorldAlarm) 	
  }
   
  setWeekday(k){
  	var editWorldAlarm = this.deapCloneEdit();
  	if(editWorldAlarm.repeat[k] == k){
  		editWorldAlarm.repeat[k] = "none";
  	}
  	else{
  		editWorldAlarm.repeat[k] = k;
  	}
  	this.props.setProp("editWorldAlarm",editWorldAlarm);
  }
 
  timeZoneChanged(timezone){
  	if(this.props.isValidTimeZone(timezone)){
    	var editWorldAlarm = this.deapCloneEdit();
    	editWorldAlarm.timezone = timezone;
    	this.props.setProp("editWorldAlarm",editWorldAlarm);
  	} 
  }
   
  timeChanged(parts,reset){
  	var editWorldAlarm = this.deapCloneEdit();
  	if(reset){
      let {clock,tzdate} = this.util_clock.getClock(this.props.editWorldAlarm.options,this.props.editWorldAlarm.locale,this.props.editWorldAlarm.timezone);
    	editWorldAlarm.parts = tzdate.getParts();
    	this.props.setProp("editWorldAlarm",editWorldAlarm);
  	}
  	else{
    	editWorldAlarm.parts = parts;
    	this.props.setProp("editWorldAlarm",editWorldAlarm);
  	}
  }
  
  doneAlarm(label,done){
  	  this.util_permission.requestPause();
    	this.props.doneAlarm();
  }
  
  cancelAlarm(alarm_key){
	  this.util_permission.requestPause();
  	this.props.cancelAlarm(alarm_key);
  }
  
  setProp(arg,value){
  	var editWorldAlarm = this.deapCloneEdit();
  	editWorldAlarm[arg] = value;
  	this.props.setProp("editWorldAlarm",editWorldAlarm);
  }
  
  setVolume(event){
  	clearTimeout(this.volumetimeout);
  	this.volumetimeout = null;
  	this.volumetimeout = setTimeout(()=>{
    	var volume = event.target.value/10;
    	this.util_permission.requestNonePausePlay(volume);
    	this.setProp("volume",volume);  		
  	},500);
  }
  
  render() {
    return (	
    	<ErrorBoundary>
  	  <div className="my-3" >
    	<SearchTimezone 
  	  timezone={this.props.editWorldAlarm.timezone} 
  	  isClicked={(timezone)=>{this.timeZoneChanged(timezone);}} 
  	  isAdded={false} title="Zone"  datalist_id="time_zones"  />
  	  </div>
  	  <TimePicker 
  	  label="Time" 
  	  timezone = {this.props.editWorldAlarm.timezone}
  	  options={this.props.editWorldAlarm.options} 
  	  locale={this.props.editWorldAlarm.locale} 
  	  parts={this.props.editWorldAlarm.parts}
  	  stateChanged={(timeobj,reset)=>{this.timeChanged(timeobj,reset);}} />    	
  	  <div className="d-flex justify-content-center align-items-center my-3" > 
  		{this.week_day_list.map((day_n) => { 
						return(
  		    		<div key={day_n} className={(this.props.editWorldAlarm.repeat[day_n] == day_n)? this.day_active_class: this.day_class } 
  		    		onClick={(event)=>{this.setWeekday(day_n);}} >{this.state.day_label[day_n]}</div>
						);	
  		})
  		}
  	  </div> 	      				    		    
		  <div className="d-flex my-3" >
 		 	  <div className="text-primary interactive-box align-self-center mr-3 text-nowrap" onClick={()=>{this.setDefaultAlarmTone();}} >{this.props.editWorldAlarm.default_alarm_tone.name}</div>
 		    <button className="text-primary interactive-box align-self-center mr-1 rounded-circle font-weight-bolder" onClick={(event)=>{this.util_permission.requestPause();}} >||</button>
 		 	  <input className="align-self-center flex-grow-1" type="range"  min="1" max="10" defaultValue="10" onInput={(event)=>{this.setVolume(event);}}   / >
		  </div> 	  
	    <div className="my-3" >
    	<WorldAlarm 
    	setProp={(arg,value)=>{this.setProp(arg,value);}}
    	parts={this.props.editWorldAlarm.parts}
    	editMode={true}
    	options={this.props.editWorldAlarm.options} 
	  	locale={this.props.editWorldAlarm.locale} 
	  	timezone={this.props.editWorldAlarm.timezone} 
    	repeat={this.props.editWorldAlarm.repeat}
    	alarm_key={this.props.editWorldAlarm.alarm_key}
    	label={this.props.editWorldAlarm.label}
    	cancelAlarm = {(alarmKey)=>{this.cancelAlarm(alarmKey);}}
	  	doneAlarm={()=>{this.doneAlarm();}} />   	
    	</div>  	
    	</ErrorBoundary>   		
    );
  }
}

AddAlarm.propTypes = {
	options: PropTypes.object,
	default_alarm_tone: PropTypes.object,
	editWorldAlarm: PropTypes.object,
	locale: PropTypes.string,
	mountModal: PropTypes.func,
	unMountModal: PropTypes.func,
	setProp: PropTypes.func,
	isValidTimeZone: PropTypes.func,
	cancelAlarm: PropTypes.func,
	doneAlarm: PropTypes.func,
};

export default AddAlarm;
