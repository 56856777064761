"use strict";
import React, { Component } from "react";
import DisplayScreen from "./DisplayScreen";

export default class Dialog extends Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return(
	  	<DisplayScreen key_val="dialog_nfhsgrt" display="ds-modal-content"  >
			<figure className="p-3">
				<figcaption className="mb-5" >
					<div className="display-8 text-center">{this.props.title}</div>
				</figcaption>
			<div className="display-8 text-primary interactive-box text-center" onClick={()=>{ this.props.on_done(true);}} >Close</div>
		 </figure>
		 </DisplayScreen>
    	)
  }
  
}