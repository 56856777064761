"use strict";
import React, { Component } from "react";
import DisplayScreen from "./DisplayScreen";
import InputAction from "./InputAction.js";
import { PlusCircleIcon} from "@primer/octicons-react";
import AlertArea from "./AlertArea.js";
import MainButton from "./MainButton.js";
import { CSSTransition, TransitionGroup} from "react-transition-group";
import {CheckIcon} from "@primer/octicons-react";
import {UtilState} from "../lib/util.js";


export default class TaskDetails extends Component {
  constructor(props) {
    super(props);
    this.state ={
    	task_title:"",
    	task_desc:"",
    	task_urgent:true,
    	task_important:true, 
    	task_delegate:false,
    	task_delegate_to:"",
    	task_items:[],
    	item:"",
    	message:null,
    	alert_type:"alert-success",
    	time_stamp:new Date().getTime(),
    	task_type:"",
    	task_type_title:""
    }
    this.util_state =  new UtilState();
    this.className = "for-button-menu text-primary interactive-box";
  }
  
  componentDidMount() {
  	try{
  		if(this.props.getTask){
  			var task_obj = this.props.getTask();
  			if(task_obj){
  				task_obj.message = null;
  				this.setState(task_obj);
  			}
  		}
  	}
  	catch(error){ 	
			 this.handleError(error);
  	}
  }
  
  componentDidUpdate(prevProps, prevState){	
  }

	onDone(type){
		if(type == "save"){
			this.props.on_done(this.state,(msg,type)=>{this.onComplete(msg,type)});
		}
		else{
			this.props.on_done(type,(msg,type)=>{this.onComplete(msg,type)});
		}
	}
	
	onComplete(msg,type){
		if(type == "alert-success"){
			msg = "Saved successfully.";
		}
		this.setState({alert_type:type, message:msg});
	}
			
	completeTaskItem(index){
		 try{
	 		  var st = this.util_state.deapClone(this.state)
	  		st.task_items[index]["task_done"] = true;
	  		this.setState(st,()=>{
		  		this.onDone("save");
	  		});
		 }
		 catch(error){
			 this.handleError(error);
		 }
	}
	
	removeAlert(){
		this.setState({message:null,alert_type:"alert-success"})
	}
	
	handleError(error){
		console.log(error);
		this.setState({message:error.message,alert_type:"alert-danger"})
	}
	
  render() {
	  return( 
	  	<DisplayScreen key_val="start_alarm_alarmlist" display="ds-modal-content ds-modal-fill p-3"  >		  
		  	<div className="d-flex flex-row justify-content-around align-items-center x-scroll h-13">
  			    <div className={this.className} onClick={()=>{this.onDone("delete");}}>Delete</div>	
  			    {this.state.task_type != "complete" &&
  			    	<div className={this.className} onClick={()=>{this.onDone("edit");}}>Edit</div>
  			    }
		  			<div className={this.className} onClick={()=>{this.onDone("move");}}>Move</div>
  			    {this.state.task_type != "complete" &&
  		  			<div className={this.className} onClick={()=>{this.onDone("complete");}}>Complete</div>
			    	}
				</div>
		  	<div className="x-scroll h-7">
					<AlertArea alert_type={this.state.alert_type} label={this.state.message} isClicked={()=>{this.removeAlert()}} />
	  	  </div>
		  	<div className="d-flex flex-column justify-content-between align-items-center x-scroll h-80 position-relative">
		  			<div className="d-flex-row-centered">
		  				<div className="d-flex-column-scrolled  w-100 p-1">			
		  					<div className="display-8 mb-2 text-center" >{this.state.task_type_title}</div>
		  					<div className="display-8 mb-2" >{this.state.task_title}</div>		
			  			  <div className="display-10 mb-2" >{this.state.task_desc}</div>
		  					<ol className="mt-3 mt-lg-4" type="1">
		  	    		{this.state.task_items.map((item,index) => { 
		  	    			return(
		  	    				<li key={index}>
		  	    				  {item.task_done && 
		  	    				  	<div className="alert alert-light alert-dismissible-other text-dark" role="alert">
	      									{item.task_item} 
	  	      							<div ref={this.btnref} type="button" className="other d-flex flex-column justify-content-center text-success"  >
	  	      							  <span className="no-line-height">
	  	      							 		<CheckIcon size={16} />
	  	      							  </span>
  	      								</div>
	      								</div>			  	    				  	
		  	    				  }
		  	    				  {!item.task_done &&
		  	    				  	<div className="alert alert-light alert-dismissible-other" role="alert">
		  	      							{item.task_item} 	  	      				
		  	      							<div ref={this.btnref} type="button" className="other btn btn-success d-flex flex-column justify-content-center"  onClick={()=>{this.completeTaskItem(index)}} >
		  	      								<div aria-hidden="true" >Done</div>
		  	      							</div>	  	  						
		  	      					</div>			  	
		  	    				  }
		  	    				</li>
		  	    			);
		  	    		}
		  	    	 )}
		  	    	 </ol>
							</div>
						</div>
					</div>
	    </DisplayScreen>
	  );   
  }  
}