"use strict";
import React, { Component } from "react";
import DisplayScreen from "./DisplayScreen";

export default class MoveTask extends Component {
  constructor(props) {
    super(props);
    this.state={moves:[],task_title:""}
  }
  
  componentDidMount() {
  	try{
  		var obj = this.props.getMoveTo();
  		this.setState(obj);
  	}
  	catch(error){ 	
  		console.log(error)
  	}
  }
  
  getElement(){
  	var element = []
  	for (const k in this.state.moves){
  		element.push(<div key={k} className="for-button-menu interactive-box text-primary" onClick={()=>{ this.props.on_done(k); }} >{this.state.moves[k]}</div> )
  	} 
  	return element;
  }
 
  render() {
    return(
	  	<DisplayScreen key_val="move_task_kw386nxmvu" display="ds-modal-content" >
			<figure className="p-3">
			<figcaption className="mb-5" >
				<div className="display-8 text-center">Move task "{this.state.task_title}" to:</div>
			</figcaption>
					<div className="d-flex justify-content-between">
					   {this.getElement()}
					</div>
		 </figure>
		 </DisplayScreen>
    	)
  }
  
}