import TZDate from "tz-date";

class UtilClock {
 getNextAlarmRepeat(options,locale,timezone,parts,day){
	 	 var timeout = -1;	 
		 if( day && parts){
			 day = parseInt(day);			 
			 var {clock:clocknow,tzdate:tznow} = this.getClock(options,locale,timezone);
		   var {clock:tzclock,tzdate:tzalarm} = this.getClock(options,locale,timezone);		   
		   if(tzclock.invalid || clocknow.invalid ){
		  	 timeout = -1;
		   }
		   else{
				 tzalarm.setHours(parts.hour,parts.minute,parts.second,parts.millisecond); 	 
		  	 var day_diff =  tzalarm.getDay() - day;
		  	 day_diff = (day_diff<0)?day_diff+7:day_diff;
		  	 tzalarm.setDate(tzalarm.getDate()+day_diff);
			   var diff =  ( tzalarm.getTime() - tznow.getTime());
			   timeout = this.getDiff(diff);
			   if(timeout == -1){
			  	 tzalarm.setDate(tzalarm.getDate()+7);
			  	 this.getNextAlarm(options,locale,timezone,tzalarm.getParts());
			   }			   
		   }
		}
		return timeout;
 }
 
 getNextAlarm(options,locale,timezone,parts){	 
	 var timeout = -1; 
 	 var {clock:clocknow,tzdate:tznow} = this.getClock(options,locale,timezone);
	 var {clock:tzclock,tzdate:tzalarm} = this.getClock(options,locale,timezone,parts);
	 if(tzclock.invalid || clocknow.invalid ){
		 timeout = -1;
	 }
	 else{
	   var diff =  ( tzalarm.getTime() - tznow.getTime());
	   timeout = this.getDiff(diff);
	 }
	 return timeout;
}
		   		  
getDiff(diff){
	 if(diff < 0){
		 return -1;
	 }
	 else {
		 return diff;
	 }
 }

 getClock(options,locale,timezone,parts){
  	var clock 	= {};
  	var tzdate 	=	{
  		getHours: ()=> null,
  		getMinutes: ()=> null,
  	};
		try{
			options = {...options};
			options.timeZone = timezone ;
			tzdate = new TZDate(options);
			tzdate.LOCALE = locale; 
			if(parts && parts.year){
				tzdate.setFullYear(parts.year,parseInt(parts.month)-1,parts.day);
				tzdate.setHours(parts.hour,parts.minute,parts.second,parts.millisecond);				
			}
			clock = tzdate.toParts();
		}
		catch(error){
			clock = { weekday: "Monday",
				invalid:true,
			  literal: "",
			  month: "",
			  day: "",
			  year: "",
			  hour: "",
			  minute: "",
			  second: "",
			  dayperiod: "",
			  timeZoneName: this.props.timezone +" is not a valid timezone",
			  millisecond: "" };
		}
		finally{
			return {"clock":clock,"tzdate":tzdate};
		}		
  }
 
 getWeekDay(day){ 	
   var _return = "";
 	switch(parseInt(day)){
  	 case 0 :
 		 _return = "S";
 		 break;
 	 case 1 :
 		 _return = "M";
 		 break;
 	 case 2 :
 		 _return = "T";
 		 break;
 	 case 3 :
 		 _return = "W";
 		 break;		    		 
 	 case 4 :
 		 _return = "T";
 		 break;		    		 
 	 case 5 :
 		 _return = "F";
 		 break;		    		 	    		 
 	 case 6 :
 		 _return = "S";
 		 break;	  			
 	}
   return _return;
 }
}

class UtilState{
	
	stateIsChanged(nextObj,currentObj,_retrun){
		if(typeof nextObj == "function"  || typeof currentObj == "function"){
			_retrun = false || _return;
		}
		else if((nextObj && !currentObj)||(!nextObj && currentObj)){
			_retrun = true;
		}
		else if( Array.isArray(nextObj) && nextObj.length > 0 ){
			for(var k = 0 ; k< nextObj.length ; k++ ){
  			_retrun = this.stateIsChanged(nextObj[k],currentObj[k],_retrun) || _retrun;
			}
		}
		else if( Array.isArray(currentObj) && currentObj.length > 0 ){
			for(var k = 0 ; k< currentObj.length ; k++ ){
  			_retrun = this.stateIsChanged(currentObj[k],nextObj[k],_retrun) || _retrun;
			}
		}
    else if (typeof nextObj == "object") {
  		for(var k in nextObj){  
  			_retrun = this.stateIsChanged(nextObj[k],currentObj[k],_retrun) || _retrun;
  		}   	
    }		
    else if(nextObj != currentObj){
    	_retrun = true;
    }
    return _retrun;
	}	
	
	stateIsChangedDetailed(nextObj,currentObj,_retrun){
		for(var k in nextObj){  
			if(currentObj == undefined){
	    	_retrun["value"] = true;
	    	_retrun["changed"][k] = k;
			}
			else if( typeof nextObj[k] == "object"){
	    	_retrun = this.stateIsChanged(nextObj[k],currentObj[k],_retrun);
	    }
	    else if ( typeof nextObj[k] != "function" && nextObj[k] != currentObj[k]){
	    	_retrun["value"] = true;
	    	_retrun["changed"][k] = k;
			}
		} 
		_retrun["value"] = _retrun["value"] || false;
		return _retrun; 
	}
	
  deapClone(o){
  	if(o && Array.isArray(o)){
  		o = [...o];
  		for (var k = 0 ; k < o.length; k ++ ){
  			o[k] = this.deapClone(o[k]);
  		}
  	}
  	else if(o && typeof o ==  "object"){  		
  		o = {...o};
  		for (var k in o){
  			o[k] = this.deapClone(o[k]);
  		}
  	}
  	return o;
  }
}

export {UtilClock,UtilState};
  