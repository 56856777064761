"use strict";
import React, { Component } from "react";
import WorldClock from "./WorldClock.js";
import { CSSTransition, TransitionGroup} from "react-transition-group";

export default class ClockList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    	return (	
				<TransitionGroup>	 
    		{this.props.worldclock.map((timezone,index) => { 
    			return(
  			<CSSTransition key={timezone} timeout={500} classNames="area">
  		  <WorldClock  
  		  	options={this.props.options} 
  		  	locale={this.props.locale} 
  		  	timezone={timezone} 
  		  	index = {index}
  		  	parts ={false}
  		  	isClicked={(timezone,index)=>{this.props.isClicked(timezone,index);}} />
  		  </CSSTransition>
				);
    		}
    	)}
			</TransitionGroup>	
     );
  }
  
}