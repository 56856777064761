"use strict";
import React, { Component } from "react";

import {GlobeIcon, StopwatchIcon, HourglassIcon, ClockIcon, LocationIcon, NoEntryIcon, PlusCircleIcon, TriangleDownIcon, ChecklistIcon} from "@primer/octicons-react";
import ErrorBoundary from "./ErrorBoundary.js";
import MainButton from "./MainButton.js";
import WorldClock from "./WorldClock.js";
import ClockList from "./ClockList";
import AlertArea from "./AlertArea.js";
import SearchTimezone from "./SearchTimezone.js";
import ClockSetting from "./ClockSetting.js";
import Calculator from "./Calculator.js";
import AddAlarm from "./AddAlarm.js";
import AlarmList from "./AlarmList";
import DisplayScreen from "./DisplayScreen";
import About from "./About";
import Planner from "./Planner";
import { CSSTransition, TransitionGroup} from "react-transition-group";
import jquery from "jquery";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";

import {TIMEZONE_LIST,LANGUAGE_COUNTRY} from "tz-date/util";
import TZDate from "tz-date";
import {UtilPermission,alarm_tone} from "../lib/utilPermission.js";
import {UtilClock,UtilState} from "../lib/util.js";

export default class MainComponent extends Component {
  constructor(props) {
    super(props);  
    this.util_permission = new UtilPermission();  
    this.util_state =  new UtilState();
    this.carouselRef = React.createRef(); 
    this.attributes = {"data-target":"#contentdisplay",  
    									"className":"btn btn-outline-light-nb res-btn"};
    this.activeAttributes  = {...this.attributes}; 
    this.activeAttributes["className"] = "btn btn-outline-light-nb res-btn active";
  	var me = this;
    this.attrs = {};
  	this.items =  {};
  	var me = this;
  	for (var k=0 ; k < 7  ; k ++){
  			((function (k) {
  	    return function(){
  	    	var index= ""+k;
  	  		me.attrs[index] = {...me.attributes , "data-slide-to": index};
  	  		me.items[index] = "carousel-item h-100";
  	    };
  		})(k))();
  	} 	
    this.state = {
    	it_ts:new Date().getTime(), 
    	attrs: {...this.attrs}, 
    	items:{...this.items},
    	sound_permission:"init",
    	show_permission_done:false,
    	alarm_msg:"",
    	show_modale_display:false,
    	world_clock_can_install_pwa:false,
    	world_clock_pwa_installed:false,
    	notification_permission:"default",
    	version:"1.0.0",
    	new_version:"1.0.0",
    	update_now:false,
    	page_available:false,
    	dialog_pipe:["tone"],
    	default_alarm_tone:{...alarm_tone[Object.keys(alarm_tone)[0]]	}
    };	
    
    if("Notification" in window){
    	this.state.dialog_pipe.splice(0,0,"notification");
    	this.state.notification_permission = Notification.permission
    }
	  this.picker = [];
	  this.picker_hashed={};
	  for (const[index,value] of TIMEZONE_LIST().entries()){
	  	this.picker.push(<option  key={value} value={value}></option>);
	  	this.picker_hashed[value]=value;
	  }
	  this.state.alertarea=null;
	  this.state.alert_warning ="d-none";
	  this.state.timestampwc = Date.now();
	  this.state.options = {...window.default_OPTIONS};		
		this.state.locale = window.navigator.language;	
		this.state.worldclock = [];
		this.state.worldalarm = {};
		this.modal_stack = [];
		this.modal_component= null;	
		
		this.langPicker = [];
		var langmap = LANGUAGE_COUNTRY()
		for (var k in langmap ){
	  	this.langPicker.push(<option key={k}  value={k}>{langmap[k]}</option>);
		}
		
		this.state.world_interval = 60000;	
  	var tzdate = new TZDate({...this.state.options});
		tzdate.LOCALE = this.state.locale; 	
		this.state.editWorldAlarm = {
			parts: tzdate.getParts(),
			alarm_key: new Date().getTime(),
			label: "Alarm",
			timezone: TZDate.tz,
			repeat: {},
  		options:{...this.state.options},
  		locale:this.state.locale,
    	default_alarm_tone:{...alarm_tone[Object.keys(alarm_tone)[0]]	},
    	volume:1
		};	
		
  	this.worldclock_update_serviceworker_force = false;
  	this.page_clockId=0
		this.page_aboutId = 4;
		this.page_settingsId = 5;
		window.addEventListener('storage', (event)=>{this.onLocalStorageEvent(event)}, false);
		localStorage.openpages = Date.now();
   }
  
  initCarousel(){
  	try{
  		this.setActive("0",true);
    	this.setSlideActive("0");
  		jquery("#contentdisplay").carousel();
  		var me = this; 		
  		jquery("#contentdisplay").on("slid.bs.carousel", event =>  {
        	me.setActive(event.to+"",false);
        	me.setSlideActive(event.to+"");
        	if(parseInt(event.to) == this.page_aboutId){
          	this.getVersion(false);        	
        	}        	     	           	
  		}); 
  		
  		jquery("input[type=range]").on("pointerdown pointerup touchstart touchend touchmove",function(event){
 		    	event.stopPropagation();
  		});
  	}
  	catch(error){
  		console.log(error);
  	}
  }


  onLocalStorageEvent(e){
    if(e.key == "openpages"){
      localStorage.page_available = Date.now();
    }
    if(e.key == "page_available"){
      window.page_available =true
      this.setState({page_available:true})
    }
  };

  componentDidMount() {
  	try{
			if(!this.state.page_available){
    		this.initServiceWorker();
    		this.initCarousel();
      	var main_component = window.localStorage.getItem("main_component");
      	if(main_component){
      		var main_component_obj = JSON.parse(main_component);
      		this.setState(main_component_obj,()=>{
      			this.setSettings(true);
      	  	this.initSetPermissionStatus(); 
          	this.initInstallHome();
          	this.getVersion(true);
      			});    		
      	}
      	else{
        	this.setSettings(true);
        	this.initSetPermissionStatus(); 
        	this.initInstallHome();
        	this.getVersion(true);
      	}
      	window.addEventListener("world_clock_un_mount_modal",(event)=>{
      		this.unMountModal();
      	}) 		
			}
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  componentWillUnmount(){
  	try{
  		jquery("#contentdisplay").off("slid.bs.carousel");
  		clearInterval(this.interval_worldclock);
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  componentDidUpdate(prevProps, prevState ){
  	try{
  			if(!this.state.page_available){
        	var state = this.deapClone();
        	delete state.editWorldAlarm 
        	state.page_available = false
        	window.localStorage.setItem("main_component",JSON.stringify(state));					
  			}			
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  getVersion(refresh){
  	jquery.get( "/version/"+this.state.version )
    .done( data => {
    	if(data && data.version ){   		
    		var ver = data.version;
    		var ver_new = data.version;
    		if(!refresh){
      		ver = this.state.version
      		ver_new = data.version;		
    		}  			
				this.setState({version:ver,new_version:ver_new,update_now:data.update_now},()=>{
					if(refresh && data.update_now == true){
						setTimeout(()=>{
					  	var event = new Event("world_clock_update_software");
					  	window.dispatchEvent(event);				
						},0);
					}
				});
    	}
    })
    .fail((error)=>{
    	console.log(error);
    });
  }
  
  initServiceWorker(){
  	if ("serviceWorker" in navigator) {
  	  navigator.serviceWorker.register("../sw.js", {
  	      scope: "./"
  	  }).then( (registration) => {
  	      var serviceWorker;
  	      if (registration.installing) {
  	          serviceWorker = registration.installing;
  	      } else if (registration.waiting) {
  	          serviceWorker = registration.waiting;
  	      } else if (registration.active) {
  	          serviceWorker = registration.active;
  	      }
  	      if (serviceWorker) {
  	          serviceWorker.addEventListener("statechange",  (e) => {
  	          });
  	      }
  	      
  	      window.addEventListener("world_clock_update_software",(event) => {
  	      	serviceWorker.postMessage({message:"world_clock_update_software"});
  	      });
  	      
  	      window.addEventListener("world_clock_set_notification",(event) => {
  	      	if(("Notification" in window) && ( Notification.permission == "granted")){
  	      		serviceWorker.postMessage({message:"world_clock_set_notification",...event.detail});		
  	      	}
  	      });
  	      
  	      window.addEventListener("world_clock_notification_remove",(event) => {
  	      	serviceWorker.postMessage({message:"world_clock_notification_remove",...event.detail});
  	      });
  	      
  	      navigator.serviceWorker.addEventListener("message", event => {
  	        if(event.data.message == "world_clock_software_updated"){	
  	        	var dialog_pipe = [...this.state.dialog_pipe]
  	        	dialog_pipe.splice(0,0,"tone")
  	        	this.setState({sound_permission:"init",show_permission_done:false,dialog_pipe:dialog_pipe},()=>{
  	        		setTimeout(()=>{
      	        	window.location.reload();
  	        		},0);
  	        	});
  	        }
  	        else if(event.data.message == "world_clock_notification_dismiss"){
  	        	var evt = new CustomEvent("world_clock_notification_dismiss", {detail:{key: event.data.key}});
  	        	window.dispatchEvent(evt);
  	        }
  	        else if(event.data.message == "world_clock_notification_pause"){
  	        	var evt = new CustomEvent("world_clock_notification_pause", {detail:{key: event.data.key}});
  	        	window.dispatchEvent(evt);
  	        }
  	      });  	          
  	      if(this.worldclock_update_serviceworker_force){
  	      	registration.update();
  	      }  	          
  	  }).catch ( (error) => {
  	  	console.log("Error serviceWorker registration " + error);
  	  });  
  	}

  }
  
  initInstallHome(){
  	window.addEventListener("appinstalled", (evt) => {
  		this.setState({world_clock_pwa_installed:true,world_clock_can_install_pwa:true});
  	});
  	
    //Code to handle install prompt on desktop
  	window.wc_can_install_paw = false;
  	window.wc_deferred_event = null;
  	window.addEventListener("beforeinstallprompt", (e) => {
    	var dialog_pipe = [...this.state.dialog_pipe]
  		dialog_pipe.splice(0,0,"paw");
  		window.wc_can_install_paw = true;
  	  e.preventDefault();
  	  window.wc_deferred_event = e;
  	  if(!this.state.world_clock_pwa_installed){
    		this.setState({world_clock_can_install_pwa:true,dialog_pipe:dialog_pipe});
  	  }
  	  window.addEventListener("world_clock_install_pwa", (event) => {
  	    window.wc_deferred_event.prompt();
  	    window.wc_deferred_event.userChoice.then((accepted) => {
  	        if (accepted.outcome === "accepted") {
  	      		this.setState({world_clock_pwa_installed:true,world_clock_can_install_pwa:true});
  	          window.wc_deferred_event = null;
  	        } else {
  	      		this.setState({world_clock_pwa_installed:false,world_clock_can_install_pwa:true});
  	        }
  	      });
  	  });  
  	});	
  }
  
  initSetPermissionStatus(){
			this.util_permission.requestPermission(
				(     )=>{this.setPermissionStatus(true);},
				(error)=>{this.setPermissionStatus(false,error);});  		
  }
  
  setPermissionStatus(status,error){  
  	if(!status){
  		this.setState({show_permission_done:false,sound_permission:"notAllowed",alarm_msg:"Grant Alarm Tone Permission"});	
  	}
  	else{
  		var dialog_pipe = [...this.state.dialog_pipe];
    	dialog_pipe.pop();
  		this.setState({dialog_pipe:dialog_pipe,show_permission_done:true,sound_permission:"allowed",alarm_msg:"Alarm Tone Permission Granted"});	  		
  	}
  }
  
  requestPermission(allow){
  	this.previd = parseInt(this.state.mainBtnActive);
  	if(allow){
	  	if(parseInt(this.state.mainBtnActive) != this.page_settingsId){
	  		jquery("#contentdisplay").carousel(this.page_settingsId);
	  	}
			this.util_permission.requestPermission(
				(     )=>{this.setState({show_permission_done:true,sound_permission:"allowed",alarm_msg:"Alarm Tone Permission Granted"});},			
				(error)=>{this.setState({show_permission_done:true,sound_permission:"failed" ,alarm_msg:"Alarm Tone Permission Failed"});}			
			);

  	}
  	else{
  		this.setState({show_permission_done:true,sound_permission:"denied",alarm_msg:"Alarm Tone Permission Denied"});
  	}
  }
  
  donePermission(){
  	var dialog_pipe = [...this.state.dialog_pipe];
  	dialog_pipe.pop();
		this.setState({dialog_pipe:dialog_pipe},()=>{
	  	if(this.state.sound_permission != "failed" && parseInt(this.state.mainBtnActive) != parseInt(this.previd)){
	  		jquery("#contentdisplay").carousel(this.previd);
	  	}	  	  	
		});
  }
  
  requestNotificationPermission(allow){
    if (allow) {
      if(this.checkBrowserNotificationPromise()) {
        Notification.requestPermission()
        .then((permission) => {
        	this.doneNotification(permission);
        });
      } else {
        Notification.requestPermission((permission) => {
        	this.doneNotification(permission);
        });
      }
    }
    else{
    	var dialog_pipe = [...this.state.dialog_pipe];
    	dialog_pipe.pop()
      this.setState({dialog_pipe:dialog_pipe});
    }
  }
  
  checkBrowserNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch(e) {
      return false;
    }
    return true;
  }
  
  doneNotification(permission){
    if(!("permission" in Notification)) {
      Notification.permission = permission;
    }
  	var dialog_pipe = [...this.state.dialog_pipe];
  	dialog_pipe.pop();
    this.setState({notification_permission:permission,dialog_pipe:dialog_pipe });
  }
  
  setSettings(init){
  	try{
    	var clock_settings = window.localStorage.getItem("clock_settings");
    	if(clock_settings ){
    		this.setState(JSON.parse(clock_settings), ()=>{
    			this.stopInterval(true);
    			this.startInterval();
        	var editWorldAlarm = this.deapClone("editWorldAlarm");
      	  editWorldAlarm.default_alarm_tone = {...this.state.default_alarm_tone};
      	  editWorldAlarm.options = {...this.state.options};
      	  editWorldAlarm.locale = this.state.locale;
      	  this.setState({editWorldAlarm:editWorldAlarm})
    		}); 
    	}
    	else{
    		this.stopInterval(true);
    		this.startInterval();
    	}
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  startInterval(){
  	if(!this.interval_worldclock && this.state.worldclock.length > 0){
  		this.interval_worldclock = setInterval(()=>{
  			this.setState({it_ts:new Date().getTime()});
  		},this.state.world_interval);
  	}
  }
  	
  stopInterval(changed){
  	if(this.interval_worldclock && (this.state.worldclock && this.state.worldclock.length == 0 || changed)){
  		clearInterval(this.interval_worldclock);
  		this.interval_worldclock = null;
    }
  }
  
  getActive(btn_id){
  	return (this.state.mainBtnActive == btn_id)? true:false;
  }
  
  setActive(btn_id,isinit){
  	try{
  	if(btn_id != this.state.mainBtnActive){
    	var attrs = {...this.state.attrs};
  		if(this.state.mainBtnActive){
  	  			attrs[this.state.mainBtnActive] = {...this.attributes};
  	  			attrs[this.state.mainBtnActive]["data-slide-to"] = this.state.mainBtnActive;  	  			
  	  }
  	  attrs[btn_id] = {...this.activeAttributes};
  	  attrs[btn_id]["data-slide-to"] = btn_id;
  		this.setState({ mainBtnActive:btn_id, attrs:attrs});	
  	}
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  setSlideActive(btn_id){
  	var items = {...this.state.items};
  	for(var k  in items){
  		items[k] = "carousel-item h-100";
  	}
  	items[btn_id] = "carousel-item h-100 active";
		this.setState({items:items});	
  }
  
  addWorldclock(timezone){
  	var index = this.state.worldclock.findIndex(item => item == timezone );
  	if( index != -1){
    	var label =  "A world clock with timezone " +timezone+ " allready exists."; 
  		this.addAlert(label);
  	}
  	else if(this.picker_hashed[timezone]==timezone){
  		var tmp =  [...this.state.worldclock];
  		tmp.push(timezone);
  		this.setState({worldclock:tmp,alert_warning:false,alertarea:null},()=>{
  			this.startInterval();
  		});
  	}
  	else{
    	var label =  timezone+ " is not a valid timezone."; 
  		if(window.isiOSPhone){
      	 label =  <span>
      	 								Type to narrow down your options and Click on 
      									<TriangleDownIcon size={32} /> to open the timezone picker.
      	 								{timezone} is not a valid timezone. 
      						</span>;
  		}
  		this.addAlert(label);	
  	}
  }
  
  removeWorldclock(timezone){
  	if(this.picker_hashed[timezone]==timezone){
  		var tmp = this.state.worldclock.filter( item => item!=timezone );
  		this.setState({worldclock:tmp},()=>{
  		   this.stopInterval();
  		});
  	}
  }
  
  addAlert(label){	
  	this.setState({alertarea:label,alert_warning:true});
  }
  
  removeAlert(key){	
  	this.setState({alertarea:null});
  }
  
  deapClone(arg){
  	if(arg ){
    	return this.util_state.deapClone(this.state[arg]); 	
  	}
  	else{
    	return this.util_state.deapClone(this.state); 	
  	}
  }
  
  doneAlarm(){
  		setTimeout(()=>{
      	var worldalarm = {...this.state.worldalarm};  	
    	  var alarm = this.deapClone("editWorldAlarm");
      	worldalarm[alarm.alarm_key]=alarm;	   	  
      	var tzdate = new TZDate({...this.state.options});
    		tzdate.LOCALE = this.state.locale;
  	  	this.setState({worldalarm:worldalarm,
  	  		editWorldAlarm:{
  	  			parts: tzdate.getParts(),
  	  			alarm_key: new Date().getTime(),
  	  			label: "Alarm",
  	  			timezone: TZDate.tz,
  	  			repeat: {},
  	    		options:{...this.state.options},
  	    		locale:this.state.locale,
  	    		default_alarm_tone:{...this.state.default_alarm_tone},
  	    		volume:1
  	  		}	
  	  	});						
  		},600); 
		  jquery("#contentdisplay").carousel(1); 
  }
  
  cancelAlarm(){	
  	var tzdate = new TZDate({...this.state.options});
		tzdate.LOCALE = this.state.locale; 
		jquery("#contentdisplay").carousel(1);
		setTimeout(()=>{
	  	this.setState({ editWorldAlarm:{
	  			parts: tzdate.getParts(),
	  			alarm_key: new Date().getTime(),
	  			label: "Alarm",
	  			timezone: TZDate.tz,
	  			repeat: {},
	    		options:{...this.state.options},
	    		locale:this.state.locale,
	    		default_alarm_tone:{...this.state.default_alarm_tone},
	    		volume:1
	  		}	
	  	});						
		},500);  		
  }
  
  toAlarmEdit(alarm_key,obj){
  	if(!alarm_key){
  		if(obj){
  	  	this.setState(
  	  		{ 
  	  			editWorldAlarm:{
  	  					parts: obj.parts,
  	  					alarm_key: new Date().getTime(),
  	  					label: "Alarm",
  	  					timezone: obj.timezone,
  	  					repeat: {},
  	  					options:{...this.state.options},
  	  					locale:this.state.locale,
  	  					default_alarm_tone:{...this.state.default_alarm_tone},
  	  					volume:1
  	  			}	
  	  		})	
  		}
			jquery("#contentdisplay").carousel(6);
  	}
  	else{
    	var worldalarm = {...this.state.worldalarm[alarm_key]};
    	this.setState({editWorldAlarm:worldalarm},()=>{
    		setTimeout(()=>{
    			jquery("#contentdisplay").carousel(6);
    		},0);
    	}); 		
  	}  	
  }
  
  toWorldClock(){
		jquery("#contentdisplay").carousel(this.page_clockId); 	
  }
  
  removeAlarm(alarm_key){
  	var worldalarm = {...this.state.worldalarm};
  	delete worldalarm[alarm_key];
  	this.setState({worldalarm:worldalarm});	
  }
  
  setProp(arg,value){
    var state = {...this.state} ;
    state[arg] = value;
  	this.setState(state);
  }
  
  mountModal(component){
  	this.modal_stack.push(component);
  	this.setState({show_modale_display:true});
  }
  
  unMountModal(){
  	this.modal_component = null;
  	this.setState({show_modale_display:false},()=>{
  		if(this.modal_stack.length > 0){
  			this.setState({show_modale_display:true});
  		}
  	});
  }
  
  getModalDisplayComponent(){
  	if(!this.modal_component && this.modal_stack.length > 0){
  		this.modal_component = this.modal_stack.shift();
  	}
  	if(this.modal_component){
  		return this.modal_component;
  	}
  	else{
  		return null;
  	}
  }
  
  addHomeScreen(add){
  	var dialog_pipe = [...this.state.dialog_pipe]
  	dialog_pipe.pop();
		this.setState({dialog_pipe:dialog_pipe},()=>{
	  	if(add){
	    	var event = new Event("world_clock_install_pwa");
	    	window.dispatchEvent(event);
	    	
	  	}	
		});
  }  
  
  goToPage(to){
  	this.setActive(to);
  	if(this.page_settingsId == to){
    	this.initSetPermissionStatus();
    	this.setState({notification_permission:(("Notification" in window)?Notification.permission:"default") }); 		
  	}
  }
  
  render() { 
    	return (	
    		<ErrorBoundary>		
    		{
    			this.state.page_available &&
    			<div className="d-flex-row-centered">
 			 			<div className="d-flex-column-centered text-center"> 
 			 				<div className="text-warning text-center" >A worldclock tab or window is allready open. Please close this one.</div>
 			 				<div className="text-warning text-center" >If you have installed the worldclock application, then close all worldclock tabs or windows and open the application.</div>
 			 			</div>
 			 		</div>	  		
    		}
    		{!this.state.page_available &&
    	   <React.Fragment>
    		{this.state.show_modale_display && this.getModalDisplayComponent()}  		
    		<div id="top_nav" className="d-flex flex-row justify-content-between align-items-center x-scroll h-13 position-relative">
						<MainButton isClicked={()=>{this.setActive("0");}} isActive={this.getActive("0")} attributes={this.state.attrs["0"]} title="Clock" 			icon={GlobeIcon} />
						<MainButton isClicked={()=>{this.setActive("1");}} isActive={this.getActive("1")} attributes={this.state.attrs["1"]} title="Alarm" 			icon={StopwatchIcon} />
						<MainButton isClicked={()=>{this.setActive("2");}} isActive={this.getActive("2")} attributes={this.state.attrs["2"]} title="Planner" 		icon={ChecklistIcon} />
						<MainButton isClicked={()=>{this.setActive("3");}} isActive={this.getActive("3")} attributes={this.state.attrs["3"]} title="Calculator" icon={ClockIcon} />
    		</div>
    		<div id="content" className="bg-light p-3  h-80 y-scroll" >
  				<div id="contentdisplay" ref= {this.carouselRef} className="carousel slide h-100"  data-touch="true" data-interval="false" data-keyboard="true" data-ride="false">
  					<div className="carousel-inner h-100" data-timestamp={this.state.timestampwc} >
  						<div className={this.state.items["0"]} >
  							<div className="my-3" >
	        				<SearchTimezone isClicked={(timezone)=>{this.addWorldclock(timezone);}} isAdded={!this.state.alert_warning} datalist_id="time_zones"  icon={PlusCircleIcon} />
	        				<AlertArea label={this.state.alertarea} isClicked={()=>{this.removeAlert();}} />
  							</div>
	        			<ClockList 
  							options={this.state.options} 
  							locale={this.state.locale} 
  							worldclock={this.state.worldclock} 
  							isClicked={(timezone)=>{this.removeWorldclock(timezone);}} />
  					 </div>
  					 <div className={this.state.items["1"]} >
							<AlarmList 
							worldalarm={this.state.worldalarm} 
							mountModal={(component)=>{this.mountModal(component);}}
							unMountModal={()=>{this.unMountModal();}}
							toAlarmEdit={(alarm_key)=>{this.toAlarmEdit(alarm_key);}}
							removeAlarm={(alarm_key)=>{this.removeAlarm(alarm_key);}} />
  					 </div>
  					 <div className={this.state.items["2"]} >
  						 <Planner mountModal={(component)=>{this.mountModal(component);}} unMountModal={()=>{this.unMountModal();}} />
  					 </div>
  					 <div className={this.state.items["3"]} >
  						 <Calculator 
  						 isValidTimeZone = {(timezone)=>{return this.picker_hashed[timezone]==timezone;}} 
  						 options={this.state.options} 
 							toAlarmEdit={(alarm_key,obj)=>{this.toAlarmEdit(alarm_key,obj);}}
  						 locale={this.state.locale}  />
  					 </div>
    				 <div className={this.state.items["4"]} >
  						 <About new_version={this.state.new_version} version={this.state.version} update_now={this.state.update_now} instalPaw={ !this.state.world_clock_pwa_installed && this.state.world_clock_can_install_pwa }/>
  					 </div>
    				 <div className={this.state.items["5"]} >
  						 <ClockSetting 
 							 mountModal={(component)=>{this.mountModal(component);}}
 							 unMountModal={()=>{this.unMountModal();}}
  						 sound_permission={this.state.sound_permission} 
  						 notification_permission={this.state.notification_permission}
  						 setProp = {(arg,value)=>{this.setProp(arg,value);}}
  						 didChange={()=>{this.setSettings();}} />
  					 </div>
    				<div className={this.state.items["6"]} >
  						 <AddAlarm 
  						 isValidTimeZone = {(timezone)=>{return this.picker_hashed[timezone]==timezone;}} 
  						 doneAlarm = {(alarm)=>{this.doneAlarm(alarm);}}
  						 cancelAlarm= {(alarmKey)=>{this.cancelAlarm(alarmKey);}}
  						 editWorldAlarm = {this.state.editWorldAlarm}
 							 mountModal={(component)=>{this.mountModal(component);}}
 							 unMountModal={()=>{this.unMountModal();}}
  						 setProp = {(arg,value)=>{this.setProp(arg,value);}}
  						 />
  					 </div>
  				</div>
  			</div>
  		</div>		
  		<div id="bottom_nav" className="d-flex flex-row justify-content-between align-items-center h-7">
  			<div  className="d-flex flex-row align-items-center">
					<MainButton isClicked={()=>{this.setActive("4");}} isActive={this.getActive("4")} attributes={this.state.attrs["4"]} title="About" />
					<MainButton isClicked={()=>{this.goToPage(this.page_settingsId)}} isActive={this.getActive(this.page_settingsId)} attributes={this.state.attrs[this.page_settingsId]} title="Settings" />
				</div>
				<MainButton isClicked={()=>{this.setActive("6");}} isActive={this.getActive("6")} attributes={this.state.attrs["6"]} title="Add Alarm" />
				</div>			
			<datalist id="time_zones" >
			{this.picker}
			</datalist>
			
			<datalist id="lang_locales" >
			{this.langPicker}
			</datalist>	
			
    	{ this.state.dialog_pipe[this.state.dialog_pipe.length -1] == "paw" && !this.state.world_clock_pwa_installed && this.state.world_clock_can_install_pwa  &&  
  	  	<DisplayScreen key_val="install_pwa_main" display="ds-modal-content" onClose={()=>{ this.addHomeScreen(false);}} >
				<div className="p-3">
				 <div className="display-8  text-primary interactive-box mb-5 text-center" onClick={()=>{ this.addHomeScreen(true);}} >Install App</div>
				 <div className="display-10 text-primary interactive-box" onClick={()=>{ this.addHomeScreen(false);}} >I will do it later from  About page</div>
			  </div>
			 </DisplayScreen>
  	  }
    	{this.state.dialog_pipe[this.state.dialog_pipe.length - 1] == "tone" && this.state.sound_permission != "init"  &&  
  	  	<DisplayScreen key_val="request_alarm_permission_main" display="ds-modal-content" 
  	  		onClose={
    				()=>{
      				this.requestPermission(false);
      				this.donePermission();   					
    				}
    			} 
    		>
				<figure className="p-3">
				<figcaption className="mb-5" >
					<div className="display-8">{this.state.alarm_msg}</div>
				</figcaption>
					{this.state.show_permission_done && this.state.sound_permission=="allowed" && <div className="display-6 text-primary interactive-box text-center" onClick={()=>{ this.donePermission();}} >Done</div>}
					{this.state.show_permission_done && this.state.sound_permission=="denied" && <div className="display-10 text-primary interactive-box text-center" onClick={()=>{ this.donePermission();}} >I will do it later from Settings page</div>}
					{this.state.show_permission_done && this.state.sound_permission=="failed" && <div className="display-10 text-primary interactive-box text-center" onClick={()=>{ this.donePermission();}} >Please click the play button on media player in settings page to enable the alarm tone manually</div>}			
					{!this.state.show_permission_done && 
						<div className="d-flex justify-content-between">
							<div className="display-6 text-primary interactive-box" onClick={()=>{ this.requestPermission(false);}} >Deny</div>
							<div className="display-6 text-primary interactive-box" onClick={()=>{ this.requestPermission(true); }} >Allow</div>
						</div>
					}
			 </figure>
			 </DisplayScreen>
  	  }   	
    	{ this.state.dialog_pipe[this.state.dialog_pipe.length -1] ==  "notification"  &&
  	  	<DisplayScreen key_val="install_pwa_main" display="ds-modal-content" onClose={()=>{ this.requestNotificationPermission(false);}} >
				<div className="p-3">
				 <div className="display-8  text-primary interactive-box mb-5 text-center" onClick={()=>{ this.requestNotificationPermission(true);}}  >Grant Alarm Notification Permission</div>
				 <div className="display-10 text-primary interactive-box text-center" onClick={()=>{ this.requestNotificationPermission(false);}} >I will do it later from Settings page</div>
			  </div>
			 </DisplayScreen>
  	  }
      </React.Fragment>
    	}
      </ErrorBoundary>
    	);
  }
}