/**
 * Static list of timeZones. Used for testing only
 * 
 * @return {array}
 */
function TIMEZONE_LIST(){
	return [
		"Africa/Abidjan","Africa/Accra","Africa/Addis_Ababa","Africa/Algiers","Africa/Asmara","Africa/Bamako","Africa/Bangui","Africa/Banjul","Africa/Bissau","Africa/Blantyre",
		"Africa/Brazzaville","Africa/Bujumbura","Africa/Cairo","Africa/Casablanca","Africa/Ceuta","Africa/Conakry","Africa/Dakar","Africa/Dar_es_Salaam","Africa/Djibouti",
		"Africa/Douala","Africa/El_Aaiun","Africa/Freetown","Africa/Gaborone","Africa/Harare","Africa/Johannesburg","Africa/Juba","Africa/Kampala","Africa/Khartoum","Africa/Kigali",
		"Africa/Kinshasa","Africa/Lagos","Africa/Libreville","Africa/Lome","Africa/Luanda","Africa/Lubumbashi","Africa/Lusaka","Africa/Malabo","Africa/Maputo","Africa/Maseru",
		"Africa/Mbabane","Africa/Mogadishu","Africa/Monrovia","Africa/Nairobi","Africa/Ndjamena","Africa/Niamey","Africa/Nouakchott","Africa/Ouagadougou","Africa/Porto-Novo",
		"Africa/Sao_Tome","Africa/Tripoli","Africa/Tunis","Africa/Windhoek","America/Adak","America/Anchorage","America/Anguilla","America/Antigua","America/Araguaina",
		"America/Argentina/Buenos_Aires","America/Argentina/Catamarca","America/Argentina/Cordoba","America/Argentina/Jujuy","America/Argentina/La_Rioja","America/Argentina/Mendoza",
		"America/Argentina/Rio_Gallegos","America/Argentina/Salta","America/Argentina/San_Juan","America/Argentina/San_Luis","America/Argentina/Tucuman","America/Argentina/Ushuaia",
		"America/Aruba","America/Asuncion","America/Atikokan","America/Bahia","America/Bahia_Banderas","America/Barbados","America/Belem","America/Belize","America/Blanc-Sablon",
		"America/Boa_Vista","America/Bogota","America/Boise","America/Cambridge_Bay","America/Campo_Grande","America/Cancun","America/Caracas","America/Cayenne","America/Cayman",
		"America/Chicago","America/Chihuahua","America/Costa_Rica","America/Creston","America/Cuiaba","America/Curacao","America/Danmarkshavn","America/Dawson","America/Dawson_Creek",
		"America/Denver","America/Detroit","America/Dominica","America/Edmonton","America/Eirunepe","America/El_Salvador","America/Fort_Nelson","America/Fortaleza",
		"America/Glace_Bay","America/Goose_Bay","America/Grand_Turk","America/Grenada","America/Guadeloupe","America/Guatemala","America/Guayaquil","America/Guyana","America/Halifax",
		"America/Havana","America/Hermosillo","America/Indiana/Indianapolis","America/Indiana/Knox","America/Indiana/Marengo","America/Indiana/Petersburg","America/Indiana/Tell_City",
		"America/Indiana/Vevay","America/Indiana/Vincennes","America/Indiana/Winamac","America/Inuvik","America/Iqaluit","America/Jamaica","America/Juneau",
		"America/Kentucky/Louisville","America/Kentucky/Monticello","America/Kralendijk","America/La_Paz","America/Lima","America/Los_Angeles","America/Lower_Princes",
		"America/Maceio","America/Managua","America/Manaus","America/Marigot","America/Martinique","America/Matamoros","America/Mazatlan","America/Menominee","America/Merida",
		"America/Metlakatla","America/Mexico_City","America/Miquelon","America/Moncton","America/Monterrey","America/Montevideo","America/Montserrat","America/Nassau",
		"America/New_York","America/Nipigon","America/Nome","America/Noronha","America/North_Dakota/Beulah","America/North_Dakota/Center","America/North_Dakota/New_Salem",
		"America/Nuuk","America/Ojinaga","America/Panama","America/Pangnirtung","America/Paramaribo","America/Phoenix","America/Port-au-Prince","America/Port_of_Spain",
		"America/Porto_Velho","America/Puerto_Rico","America/Punta_Arenas","America/Rainy_River","America/Rankin_Inlet","America/Recife","America/Regina","America/Resolute",
		"America/Rio_Branco","America/Santarem","America/Santiago","America/Santo_Domingo","America/Sao_Paulo","America/Scoresbysund","America/Sitka","America/St_Barthelemy",
		"America/St_Johns","America/St_Kitts","America/St_Lucia","America/St_Thomas","America/St_Vincent","America/Swift_Current","America/Tegucigalpa","America/Thule",
		"America/Thunder_Bay","America/Tijuana","America/Toronto","America/Tortola","America/Vancouver","America/Whitehorse","America/Winnipeg","America/Yakutat",
		"America/Yellowknife","Antarctica/Casey","Antarctica/Davis","Antarctica/DumontDUrville","Antarctica/Macquarie","Antarctica/Mawson","Antarctica/McMurdo","Antarctica/Palmer",
		"Antarctica/Rothera","Antarctica/Syowa","Antarctica/Troll","Antarctica/Vostok","Arctic/Longyearbyen","Asia/Aden","Asia/Almaty","Asia/Amman","Asia/Anadyr","Asia/Aqtau",
		"Asia/Aqtobe","Asia/Ashgabat","Asia/Atyrau","Asia/Baghdad","Asia/Bahrain","Asia/Baku","Asia/Bangkok","Asia/Barnaul","Asia/Beirut","Asia/Bishkek","Asia/Brunei","Asia/Chita",
		"Asia/Choibalsan","Asia/Colombo","Asia/Damascus","Asia/Dhaka","Asia/Dili","Asia/Dubai","Asia/Dushanbe","Asia/Famagusta","Asia/Gaza","Asia/Hebron","Asia/Ho_Chi_Minh",
		"Asia/Hong_Kong","Asia/Hovd","Asia/Irkutsk","Asia/Jakarta","Asia/Jayapura","Asia/Jerusalem","Asia/Kabul","Asia/Kamchatka","Asia/Karachi","Asia/Kathmandu","Asia/Khandyga",
		"Asia/Kolkata","Asia/Krasnoyarsk","Asia/Kuala_Lumpur","Asia/Kuching","Asia/Kuwait","Asia/Macau","Asia/Magadan","Asia/Makassar","Asia/Manila","Asia/Muscat","Asia/Nicosia",
		"Asia/Novokuznetsk","Asia/Novosibirsk","Asia/Omsk","Asia/Oral","Asia/Phnom_Penh","Asia/Pontianak","Asia/Pyongyang","Asia/Qatar","Asia/Qostanay","Asia/Qyzylorda","Asia/Riyadh",
		"Asia/Sakhalin","Asia/Samarkand","Asia/Seoul","Asia/Shanghai","Asia/Singapore","Asia/Srednekolymsk","Asia/Taipei","Asia/Tashkent","Asia/Tbilisi","Asia/Tehran","Asia/Thimphu",
		"Asia/Tokyo","Asia/Tomsk","Asia/Ulaanbaatar","Asia/Urumqi","Asia/Ust-Nera","Asia/Vientiane","Asia/Vladivostok","Asia/Yakutsk","Asia/Yangon","Asia/Yekaterinburg",
		"Asia/Yerevan","Atlantic/Azores","Atlantic/Bermuda","Atlantic/Canary","Atlantic/Cape_Verde","Atlantic/Faroe","Atlantic/Madeira","Atlantic/Reykjavik","Atlantic/South_Georgia",
		"Atlantic/St_Helena","Atlantic/Stanley","Australia/Adelaide","Australia/Brisbane","Australia/Broken_Hill","Australia/Currie","Australia/Darwin","Australia/Eucla",
		"Australia/Hobart","Australia/Lindeman","Australia/Lord_Howe","Australia/Melbourne","Australia/Perth","Australia/Sydney","Europe/Amsterdam","Europe/Andorra",
		"Europe/Astrakhan","Europe/Athens","Europe/Belgrade","Europe/Berlin","Europe/Bratislava","Europe/Brussels","Europe/Bucharest","Europe/Budapest","Europe/Busingen",
		"Europe/Chisinau","Europe/Copenhagen","Europe/Dublin","Europe/Gibraltar","Europe/Guernsey","Europe/Helsinki","Europe/Isle_of_Man","Europe/Istanbul","Europe/Jersey",
		"Europe/Kaliningrad","Europe/Kiev","Europe/Kirov","Europe/Lisbon","Europe/Ljubljana","Europe/London","Europe/Luxembourg","Europe/Madrid","Europe/Malta","Europe/Mariehamn",
		"Europe/Minsk","Europe/Monaco","Europe/Moscow","Europe/Oslo","Europe/Paris","Europe/Podgorica","Europe/Prague","Europe/Riga","Europe/Rome","Europe/Samara","Europe/San_Marino",
		"Europe/Sarajevo","Europe/Saratov","Europe/Simferopol","Europe/Skopje","Europe/Sofia","Europe/Stockholm","Europe/Tallinn","Europe/Tirane","Europe/Ulyanovsk","Europe/Uzhgorod",
		"Europe/Vaduz","Europe/Vatican","Europe/Vienna","Europe/Vilnius","Europe/Volgograd","Europe/Warsaw","Europe/Zagreb","Europe/Zaporozhye","Europe/Zurich","Indian/Antananarivo",
		"Indian/Chagos","Indian/Christmas","Indian/Cocos","Indian/Comoro","Indian/Kerguelen","Indian/Mahe","Indian/Maldives","Indian/Mauritius","Indian/Mayotte","Indian/Reunion",
		"Pacific/Apia","Pacific/Auckland","Pacific/Bougainville","Pacific/Chatham","Pacific/Chuuk","Pacific/Easter","Pacific/Efate","Pacific/Enderbury","Pacific/Fakaofo",
		"Pacific/Fiji","Pacific/Funafuti","Pacific/Galapagos","Pacific/Gambier","Pacific/Guadalcanal","Pacific/Guam","Pacific/Honolulu","Pacific/Kiritimati","Pacific/Kosrae",
		"Pacific/Kwajalein","Pacific/Majuro","Pacific/Marquesas","Pacific/Midway","Pacific/Nauru","Pacific/Niue","Pacific/Norfolk","Pacific/Noumea","Pacific/Pago_Pago",
		"Pacific/Palau","Pacific/Pitcairn","Pacific/Pohnpei","Pacific/Port_Moresby","Pacific/Rarotonga","Pacific/Saipan","Pacific/Tahiti","Pacific/Tarawa","Pacific/Tongatapu",
		"Pacific/Wake","Pacific/Wallis","UTC"
	];
}

function LANGUAGE_COUNTRY(){
	return {
	"af-ZA":"Afrikaans (South Africa)",
	"ar-AE":"Arabic (U.A.E.)",
	"ar-BH":"Arabic (Bahrain)",
	"ar-DZ":"Arabic (Algeria)",
	"ar-EG":"Arabic (Egypt)",
	"ar-IQ":"Arabic (Iraq)",
	"ar-JO":"Arabic (Jordan)",
	"ar-KW":"Arabic (Kuwait)",
	"ar-LB":"Arabic (Lebanon)",
	"ar-LY":"Arabic (Libya)",
	"ar-MA":"Arabic (Morocco)",
	"ar-OM":"Arabic (Oman)",
	"ar-QA":"Arabic (Qatar)",
	"ar-SA":"Arabic (Saudi Arabia)",
	"ar-SY":"Arabic (Syria)",
	"ar-TN":"Arabic (Tunisia)",
	"ar-YE":"Arabic (Yemen)",
	"az-AZ":"Azeri (Latin) (Azerbaijan)",
	"az-AZ":"Azeri (Cyrillic) (Azerbaijan)",
	"be-BY":"Belarusian (Belarus)",
	"bg-BG":"Bulgarian (Bulgaria)",
	"bs-BA":"Bosnian (Bosnia and Herzegovina)",
	"ca-ES":"Catalan (Spain)",
	"cs-CZ":"Czech (Czech Republic)",
	"cy-GB":"Welsh (United Kingdom)",
	"da-DK":"Danish (Denmark)",
	"de-AT":"German (Austria)",
	"de-CH":"German (Switzerland)",
	"de-DE":"German (Germany)",
	"de-LI":"German (Liechtenstein)",
	"de-LU":"German (Luxembourg)",
	"dv-MV":"Divehi (Maldives)",
	"el-GR":"Greek (Greece)",
	"en-AU":"English (Australia)",
	"en-BZ":"English (Belize)",
	"en-CA":"English (Canada)",
	"en-CB":"English (Caribbean)",
	"en-GB":"English (United Kingdom)",
	"en-IE":"English (Ireland)",
	"en-JM":"English (Jamaica)",
	"en-NZ":"English (New Zealand)",
	"en-PH":"English (Republic of the Philippines)",
	"en-TT":"English (Trinidad and Tobago)",
	"en-US":"English (United States)",
	"en-ZA":"English (South Africa)",
	"en-ZW":"English (Zimbabwe)",
	"es-AR":"Spanish (Argentina)",
	"es-BO":"Spanish (Bolivia)",
	"es-CL":"Spanish (Chile)",
	"es-CO":"Spanish (Colombia)",
	"es-CR":"Spanish (Costa Rica)",
	"es-DO":"Spanish (Dominican Republic)",
	"es-EC":"Spanish (Ecuador)",
	"es-ES":"Spanish (Castilian)",
	"es-ES":"Spanish (Spain)",
	"es-GT":"Spanish (Guatemala)",
	"es-HN":"Spanish (Honduras)",
	"es-MX":"Spanish (Mexico)",
	"es-NI":"Spanish (Nicaragua)",
	"es-PA":"Spanish (Panama)",
	"es-PE":"Spanish (Peru)",
	"es-PR":"Spanish (Puerto Rico)",
	"es-PY":"Spanish (Paraguay)",
	"es-SV":"Spanish (El Salvador)",
	"es-UY":"Spanish (Uruguay)",
	"es-VE":"Spanish (Venezuela)",
	"et-EE":"Estonian (Estonia)",
	"eu-ES":"Basque (Spain)",
	"fa-IR":"Farsi (Iran)",
	"fi-FI":"Finnish (Finland)",
	"fo-FO":"Faroese (Faroe Islands)",
	"fr-BE":"French (Belgium)",
	"fr-CA":"French (Canada)",
	"fr-CH":"French (Switzerland)",
	"fr-FR":"French (France)",
	"fr-LU":"French (Luxembourg)",
	"fr-MC":"French (Principality of Monaco)",
	"gl-ES":"Galician (Spain)",
	"gu-IN":"Gujarati (India)",
	"he-IL":"Hebrew (Israel)",
	"hi-IN":"Hindi (India)",
	"hr-BA":"Croatian (Bosnia and Herzegovina)",
	"hr-HR":"Croatian (Croatia)",
	"hu-HU":"Hungarian (Hungary)",
	"hy-AM":"Armenian (Armenia)",
	"id-ID":"Indonesian (Indonesia)",
	"is-IS":"Icelandic (Iceland)",
	"it-CH":"Italian (Switzerland)",
	"it-IT":"Italian (Italy)",
	"ja-JP":"Japanese (Japan)",
	"ka-GE":"Georgian (Georgia)",
	"kk-KZ":"Kazakh (Kazakhstan)",
	"kn-IN":"Kannada (India)",
	"ko-KR":"Korean (Korea)",
	"kok-IN":"Konkani (India)",
	"ky-KG":"Kyrgyz (Kyrgyzstan)",
	"lt-LT":"Lithuanian (Lithuania)",
	"lv-LV":"Latvian (Latvia)",
	"mi-NZ":"Maori (New Zealand)",
	"mk-MK":"FYRO Macedonian (Former Yugoslav Republic of Macedonia)",
	"mn-MN":"Mongolian (Mongolia)",
	"mr-IN":"Marathi (India)",
	"ms-BN":"Malay (Brunei Darussalam)",
	"ms-MY":"Malay (Malaysia)",
	"mt-MT":"Maltese (Malta)",
	"nb-NO":"Norwegian (Bokm?l) (Norway)",
	"nl-BE":"Dutch (Belgium)",
	"nl-NL":"Dutch (Netherlands)",
	"nn-NO":"Norwegian (Nynorsk) (Norway)",
	"ns-ZA":"Northern Sotho (South Africa)",
	"pa-IN":"Punjabi (India)",
	"pl-PL":"Polish (Poland)",
	"ps-AR":"Pashto (Afghanistan)",
	"pt-BR":"Portuguese (Brazil)",
	"pt-PT":"Portuguese (Portugal)",
	"qu-BO":"Quechua (Bolivia)",
	"qu-EC":"Quechua (Ecuador)",
	"qu-PE":"Quechua (Peru)",
	"ro-RO":"Romanian (Romania)",
	"ru-RU":"Russian (Russia)",
	"sa-IN":"Sanskrit (India)",
	"se-FI":"Sami (Northern) (Finland)",
	"se-FI":"Sami (Skolt) (Finland)",
	"se-FI":"Sami (Inari) (Finland)",
	"se-NO":"Sami (Northern) (Norway)",
	"se-NO":"Sami (Lule) (Norway)",
	"se-NO":"Sami (Southern) (Norway)",
	"se-SE":"Sami (Northern) (Sweden)",
	"se-SE":"Sami (Lule) (Sweden)",
	"se-SE":"Sami (Southern) (Sweden)",
	"sk-SK":"Slovak (Slovakia)",
	"sl-SI":"Slovenian (Slovenia)",
	"sq-AL":"Albanian (Albania)",
	"sr-BA":"Serbian (Latin) (Bosnia and Herzegovina)",
	"sr-BA":"Serbian (Cyrillic) (Bosnia and Herzegovina)",
	"sr-SP":"Serbian (Latin) (Serbia and Montenegro)",
	"sr-SP":"Serbian (Cyrillic) (Serbia and Montenegro)",
	"sv-FI":"Swedish (Finland)",
	"sv-SE":"Swedish (Sweden)",
	"sw-KE":"Swahili (Kenya)",
	"syr-SY":"Syriac (Syria)",
	"ta-IN":"Tamil (India)",
	"te-IN":"Telugu (India)",
	"th-TH":"Thai (Thailand)",
	"tl-PH":"Tagalog (Philippines)",
	"tn-ZA":"Tswana (South Africa)",
	"tr-TR":"Turkish (Turkey)",
	"tt-RU":"Tatar (Russia)",
	"uk-UA":"Ukrainian (Ukraine)",
	"ur-PK":"Urdu (Islamic Republic of Pakistan)",
	"uz-UZ":"Uzbek (Latin) (Uzbekistan)",
	"uz-UZ":"Uzbek (Cyrillic) (Uzbekistan)",
	"vi-VN":"Vietnamese (Viet Nam)",
	"xh-ZA":"Xhosa (South Africa)",
	"zh-CN":"Chinese (S)",
	"zh-HK":"Chinese (Hong Kong)",
	"zh-MO":"Chinese (Macau)",
	"zh-SG":"Chinese (Singapore)",
	"zh-TW":"Chinese (T)",
	"zu-ZA":"Zulu (South Africa)"
	}
	}

export {TIMEZONE_LIST,LANGUAGE_COUNTRY};

