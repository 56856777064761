var alarm_tone = {};

var soundtrackFn = require.context("../",true, /(sound).*\.mp3$/);
soundtrackFn.keys().forEach(function(src,index,array){
	try{
	  var name = src.split("/").slice(-1)[0].split(".mp3")[0];
	  alarm_tone[name] = {name:name,src:"/server/"+name+".mp3"};
		soundtrackFn(src);		
	}
	catch(error){
		console.log("UtilPermission: "+ error.message);
	}
});

 class UtilPermission {
	
  constructor(){
  }
  
  requestNonePausePlay(volume){
  	try{
  		var audio = document.querySelector("#main_alarm_player") ;
  		if(!audio.playing){	
    		var promise = audio.play(); 
  		}
  		if(volume){
  			audio.volume = volume ;
  		}
  		else{
    		audio.volume = 1 ; 
  		}
		
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  requestPlay(src,volume){
  	try{
  		var audio = document.querySelector("#main_alarm_player") ;
  		if(src){
    		var source = document.querySelector("#main_alarm_player_src") ;
    		source.src = src;	
  		}
  		if(volume){
  			audio.volume = volume ;
  		}
  		else{
    		audio.volume = 1 ; 
  		}
  		audio.currentTime = 0;
  		audio.pause();
  		audio.load();
  		var promise = audio.play(); 
			if(promise !== undefined){
				promise.catch(error => {
					console.log(error);
				})
			}
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  requestPause(){
  	try{
  		var audio = document.querySelector("#main_alarm_player") ;
  		audio.volume = 1 ; 
  		audio.currentTime = 0;
  		var promise = audio.pause(); 		
  	}
  	catch(error){
  		console.log(error);
  	}	
  }
	
  requestPermission(onSuccess,onError){
		try{
			var audio = document.querySelector("#main_alarm_player") ;
			audio.volume = 1 ; 
			var promise = audio.play();
			if(promise !== undefined){
				promise.catch(error => {
					return error;
				}).then((error) => {
					try{
						audio.pause();	
					}
					catch(e){}				
					if(error && typeof onError == "function"){
						onError(error.name);
					}
					else if(!error && typeof onSuccess == "function"){
						onSuccess();
					}
				});
			}
			else{
				try{
					audio.pause();	
				}
				catch(e){}
				if(typeof onError == "function"){
					onError(-1);
				}				
			}
		}
		catch(e){
			console.trace(e);
		}
	}
}

export {UtilPermission,alarm_tone};
  