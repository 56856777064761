"use strict";
import React, { Component } from "react";
import ErrorBoundary from "./ErrorBoundary.js";


export default class MainButton extends Component {
	/**
	 * @param {object} props {icon,direction,title,textstyle}
	 * icon: default none: an react component.
	 * direction: default none or set to text-center 'icon aligned on top of text': d-inline 'icon aligned to left'.
	 * iconsize: String default none: a number representation without any unit 16 or 32 etc...
	 * title: String default none
	 * textstyle: {} default none: a style object in react notion, {fontSize:1rem}
	 */
  constructor(props) {
    super(props);
  }
  
  handleClick() {
  	if(!this.props.isActive){
    	this.props.isClicked();	
  	}	
  }
 
  render() {
  	
      var icon = null;
      
  		if(this.props.icon){
      	icon = <div className={this.props.direction} >
    						<this.props.icon aria-label={this.props.title} size={this.props.iconsize} /> 
    					</div>; 
  		}
 	
    	return (	
    		<ErrorBoundary>
        <button type="button" {...this.props.attributes} onClick={()=>{this.handleClick()}} >
        		{icon}
          	<span style={this.props.textstyle} >{this.props.title}</span>
        </button>
    		</ErrorBoundary>
    	);
  }
  
}