"use strict";
import React, { Component } from "react";
import ErrorBoundary from "./ErrorBoundary.js";
import DatePicker from "./DatePicker.js";
import InputAction from "./InputAction.js";
import {UtilClock,UtilState} from "../lib/util.js";

export default class WorldAlarm extends Component {
	
  constructor(props) {
    super(props);
    this.state 		= {expire:false,dirty:false};
    this.labelRef = React.createRef();
    this.classMain = "alert alert-light alert-dismissible d-lg-flex flex-row justify-content-between flex-wrap align-items-center";
    if(this.props.editMode){
      this.classMain = "alert alert-light alert-dismissible-other d-lg-flex flex-row justify-content-between flex-wrap align-items-center";
    }
    this.util_clock = new UtilClock();
    this.util_state =  new UtilState();
    this.setTZDate({...this.props.parts},true);
  }
  
  componentWillUnmount(){
  	if(!window.page_available){
    	this.clearTimers();
  	}
  }
 
  componentDidMount() {
  	try{
  		if(!this.props.editMode && !this.props.alarmShowOnly){
  			 this.setTZDate({...this.props.parts},false);
  		}
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  componentDidUpdate(prevProps, prevState){
      var props_return = this.util_state.stateIsChanged(prevProps.parts, this.props.parts,false);
      var state_return = this.util_state.stateIsChanged(prevState.clock, this.state.clock,false);
      if(props_return && !state_return){  
    		this.setTZDate(this.props.parts,false);
      }
  }
  
  clearTimers(){
  	try{
    	clearTimeout(this.alarm_repeat_timeout);
    	clearTimeout(this.alarm_timeout);
    	clearInterval(this.alarm_interval);
    	this.alarm_repeat_timeout = null;
    	this.alarm_timeout = null;
    	this.alarm_interval = null;	 	
    	this.removeNotification(); 		
  	}
  	catch(error){
  		console.log(error)
  	}
  }
  
  setAlarm(){
		this.clearTimers();
    var {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale,this.props.timezone,{...this.props.parts});
    var specific_day =  tzdate.getDay();
    var set_specific = true;
    var expire = true;
    var list = [];
		for (var k in this.props.repeat){
			if( this.props.repeat[k] == k){
		    set_specific = (specific_day == parseInt(k))?false:set_specific;
		    var timeout = this.util_clock.getNextAlarmRepeat({...this.props.options},this.props.locale,this.props.timezone,{...this.props.parts},k);		    
		    if(timeout != -1){
			    expire = false;
			    this.setAlarmRepeat(timeout);
		    }
			}
  	}

    if(set_specific){
	    var timeout = this.util_clock.getNextAlarm({...this.props.options},this.props.locale,this.props.timezone,{...this.props.parts});		       	
	    if(timeout >=0 && timeout < (86499*1000)){
			    expire = false;
		    	this.alarm_interval = null;
		    	this.setAlarmSingle(timeout);
	    }
	    else if(timeout >= (86499*1000)){
 			 this.alarm_interval = setInterval(()=>{
				 this.setAlarm();
			 },86400*1000);
	    }
    }   
    this.setState({expire:expire});		
  }
  
  setAlarmRepeat(timeout){
  	this.setNotification(timeout);
  	this.alarm_repeat_timeout = setTimeout(()=>{
  		this.startAlarm();
  		setTimeout(()=>{
    		this.setAlarm();		
  		},1100);
  	},timeout);
  }
  
  setAlarmSingle(timeout){
  	this.setNotification(timeout);
  	this.alarm_timeout = setTimeout(()=>{
  		this.startAlarm();
  	},timeout);
  }
  
  setNotification(timeout){
  	var evt = new CustomEvent("world_clock_set_notification", {detail:{key: this.props.alarm_key,timeout:timeout}});
  	window.dispatchEvent(evt);
  }
  
  removeNotification(){
  	var evt = new CustomEvent("world_clock_notification_remove", {detail:{key: this.props.alarm_key}});
  	window.dispatchEvent(evt);
  }
  
  startAlarm(){
  	this.props.startAlarm(this.props.alarm_key);	
  }
  
  setTZDate(parts,init){
  	parts.second=0;
  	parts.millisecond=0;
    var {clock,tzdate} = this.util_clock.getClock(
    	{...this.props.options},
    	this.props.locale,
    	this.props.timezone,
    	parts
    	);
    if(init){
    	this.state.clock = clock;
    }
    else{
      this.setState({clock:clock},()=>{
    		if(!this.props.editMode && !this.props.alarmShowOnly){
   			 this.setAlarm();
    		}
      });   	
    }
  }

  toWeekDayStr(){
  	if(this.props.editMode){
  		return [];
  	}
  	var localdate = [];
  	var _return = "";
		for (var k in this.props.repeat){
			if( this.props.repeat[k] == k){
		     switch(parseInt(k)){
		    	 case 0 :
		    		 localdate.push("Sa");
		    		 break;
		    	 case 1 :
		    		 localdate.push("M");
		    		 break;
		    	 case 2 :
		    		 localdate.push("Tu");
		    		 break;
		    	 case 3 :
		    		 localdate.push("W");
		    		 break;		    		 
		    	 case 4 :
		    		 localdate.push("Th");
		    		 break;		    		 
		    	 case 5 :
		    		 localdate.push("F");
		    		 break;		    		 	    		 
		    	 case 6 :
		    		 localdate.push("Su");
		    		 break;		    		 	    		 
		     }	
			}
  	}
		if(localdate.length > 0 ){
			_return = <div>Repeat every {localdate.join(", ")}</div>;
		}
		else{
			_return = <div></div>;
		}
		return _return;
  }
  
  toAlarmEdit(){
  	if(!this.props.editMode){
  			this.props.toAlarmEdit(this.props.alarm_key);
  	}
  }
  
  doneAlarm(){
  	this.props.doneAlarm();
  }
  
  cancelAlarm(){
  	this.props.cancelAlarm(this.props.alarm_key);
  }
  
  removeAlarm(){  
  	this.props.removeAlarm(this.props.alarm_key);
  }
  
  setPropDate(arg,value){
  	if(arg == "dirty"){
    	this.setState({dirty:true});
  	}
  	else{
    	this.setState({dirty:false});	
  	}
  	this.props.setProp(arg,value);
  }
  
  setProp(arg,value){
  	this.props.setProp(arg,value);
  }
  
  getButtons(editmode){
	 var btn =
			<button type="button" className="close text-danger" aria-label="Remove" onClick={(event)=>{this.removeAlarm(event);}}>
				<span aria-hidden="true">&times;</span>
			</button>;
  	
		if(editmode){

		  	btn = 
			  	<div className="other btn btn-primary curved-shadow-primary d-flex flex-column justify-content-between" disabled={this.state.dirty}>
						<div className="line-h-1" onClick={()=>{this.doneAlarm(true);}}>
							{!this.state.dirty && <span >Done</span> }
						</div>
						<div className="line-h-1" onClick={()=>{this.cancelAlarm();}}>
							<span>Cancel</span>
						</div>
					</div>;		

		}
		
		return btn;
  }
  
  render() {
			var tz = this.props.timezone.split("/");	
			var showTimezone = "d-block";
			var showTime = "interactive-box text-primary d-flex";
			if(this.props.editMode){
				showTimezone = "d-none";
				showTime = "d-none";		
			}
			if(this.props.alarmShowOnly){
				showTimezone = "d-block";
				showTime = "text-primary d-flex";	
			}
			
    	return (	
    		<ErrorBoundary>
					<div className={this.classMain} role="alert">
		  			<div className={showTime} onClick={()=>{this.toAlarmEdit();}}  >
		  				<div className="display-6">{this.state.clock.hour}:{this.state.clock.minute}</div> 
	  				  <div className="d-flex flex-column justify-content-center display-6-subsup" >
		  					<div className="align-self-center">{this.state.clock.second}&nbsp;</div>
		  					<div className="align-self-center">{this.state.clock.dayPeriod}&nbsp;</div>
		  				</div>
		  				{this.state.expired &&
		  					<div>expired</div>
		  					}
		  			</div>
		  			<InputAction 
		  			editMode={this.props.editMode} 
		  			label={this.props.label} 
		  			setProp={(arg,value)=>{this.setProp(arg,value);}} />
		  			<div className={showTimezone}>{this.state.clock.timeZoneName} - {tz[tz.length-1]} </div>	
		  			{this.toWeekDayStr()} 			
		  			<DatePicker 
		  				parts={this.props.parts} 
		  			  clock={this.state.clock}
		  			  setProp={(arg,value)=>{this.setPropDate(arg,value);}}
		  				editMode={this.props.editMode} />
		  				{!this.props.alarmShowOnly && this.getButtons(this.props.editMode)}	

		  	 </div>
        </ErrorBoundary>
    	);
  }
  
}