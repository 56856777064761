"use strict";
import React, { Component } from "react";
import {TriangleUpIcon, TriangleDownIcon} from "@primer/octicons-react";
import {UtilClock,UtilState} from "../lib/util.js";

export default class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.huInput = React.createRef();
    this.muInput = React.createRef();
    this.ampmClass = "display-7 ml-1 border-ridge text-center curved-shadow-simple interactive-box min-width-3-5";
    this.resetClass = "display-10 mr-1 border-ridge text-center curved-shadow-simple interactive-box";
    this.state = {
    		iconsize:48,
    		maxWidth:(48+6)+"px",
    		reset:false,
    		hro: 1,
    		huo: 0,
    		hrz: "1",
    		huz: "-1",   	
    		mro: 1,
    		muo: 0,
    		mrz: "1",
    		muz: "-1",
    		parts: this.props.parts
    	};
    this.util_clock = new UtilClock();
    this.util_state =  new UtilState();
	  let {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,{...this.props.parts});
	  this.state.clock = clock;
	  this.state.parts =  tzdate.getParts();
  }
  
  componentDidMount() {
    this.huInput.current.value = this.state.parts.hour;
  }
    
  componentDidUpdate(prevProps, prevState){
    var props_return =  this.util_state.stateIsChanged(prevProps.parts , this.props.parts, false);
    var state_return =  this.util_state.stateIsChanged(prevState.parts , this.state.parts, false);
  	if(!props_return && state_return){ 
  		this.props.stateChanged({...this.state.parts},false);  			
  	}
  	else if(props_return && !state_return){
  	  let {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,{...this.props.parts});
  	  this.setState({clock:clock,parts:tzdate.getParts()},()=>{
  			this.huInput.current.value = this.state.parts.hour;
  			this.muInput.current.value = this.state.parts.minute;
  	  });
  	}
  }
  
  hourInDEcrease(value){
	  value =  parseInt(value) % 24;
	  var parts = {...this.state.parts};
	  parts.hour = value;
	  let {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,parts);
  	this.setState({parts:tzdate.getParts(),clock:clock,reset:false,hro:1,huo:0,hrz:"1",huz:"-1"},()=>{
			this.huInput.current.value = this.state.parts.hour
  	});
  }

  hourInput(value){
  	clearTimeout(this.hour_timeout);
  	this.hour_timeout = null;
  	this.hour_timeout= setTimeout(()=>{
  		value = parseInt(value);
  		value = isNaN(value)?0:value;
  		var parts = {...this.state.parts};
  		parts.hour = value;
  		var {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,parts);
  		this.setState({parts:tzdate.getParts(),clock:clock,reset:false,hro:1,huo:0,hrz:"1",huz:"-1"},()=>{
  			this.huInput.current.value = this.state.parts.hour
  		}); 
  	},window.input_delay);  			
  }
 
  
  hourClick(event){
  	this.setState({hro:0,huo:1,hrz:"-1",huz:"1"},()=>{
    	this.huInput.current.focus();
    	this.hour_timeout = setTimeout(()=>{
    		this.setState({hro:1,huo:0,hrz:"1",huz:"-1"}); 	
    	},window.input_delay_inactive);
  	});
  }
  
  minuteInDEcrease(value){
	  var parts = {...this.state.parts};
	  parts.minute = value;
  	let {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,parts);
		this.setState({parts:tzdate.getParts(),clock:clock,reset:false,mro:1,muo:0,mrz:"1",muz:"-1"},()=>{
			this.muInput.current.value = this.state.parts.minute;
		});
  }
  
  minuteInput(value){	
  	clearTimeout(this.minute_timeout);
  	this.minute_timeout = null;
    this.minute_timeout= setTimeout(()=>{
    	  var parts = {...this.state.parts};
    	  value = parseInt(value); 
    	  if(isNaN(value)){ 
    	  	value = 0;
    	  }
    	  else if(value > 59){
    	  	value = 59;
    	  }
    		else if(value <0){
    			value = 0; 
    		}
    	  parts.minute = value;
    		let {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,parts);
    		this.setState({parts:tzdate.getParts(),clock:clock,reset:false,mro:1,muo:0,mrz:"1",muz:"-1"},()=>{
    			this.muInput.current.value = this.state.parts.minute;
    		}); 
    	},window.input_delay); 
  }
  
  minuteClick(event){
  	this.setState({mro:0,muo:1,mrz:"-1",muz:"1"},()=>{
    	this.muInput.current.focus();
    	this.minute_timeout = setTimeout(()=>{
    		this.setState({mro:1,muo:0,mrz:"1",muz:"-1"}); 	
    	},window.input_delay_inactive);
  	});
  }
  
  periodClick(event){
	  var parts = {...this.state.parts};
	  parts.hour = (parseInt(parts.hour) + 12) % 24;
		let {clock,tzdate} = this.util_clock.getClock({...this.props.options},this.props.locale, this.props.timezone,parts);
		this.setState({parts:tzdate.getParts(),clock:clock,reset:false,hro:1,huo:0,hrz:"1",huz:"-1"});
  }

  
  resetTime(event){
		this.props.stateChanged(null,true);  			
  }

  render() {
  	  var ampm = (this.props.options.hourCycle == "h12")? this.ampmClass: "d-none";
    	return (	
      	<div className="d-flex justify-content-center align-items-center" >
        	<div className={this.resetClass} onClick={(event)=>{this.resetTime(event);}} >Now</div>
      		<div className="position-relative curved-shadow" >
      			<div className="text-primary text-center interactive-box" 
      				onClick={(event)=>{this.hourInDEcrease(parseInt(this.props.parts.hour) + 1);} } >
      	  		<TriangleUpIcon size={ this.state.iconsize} />
      	  	</div>    	  		
      	    <input style={{"maxWidth":this.state.maxWidth,"opacity":this.state.hro,"zIndex":this.state.hrz}}  
      	    onClick={(event)=>{this.hourClick(event);}} onInput={()=>{}} type="text"
      	    className="display-7 border-ridge text-center position-absolute" 
      	  	value={this.state.clock.hour} />   	 
      	    <input style={{"maxWidth":this.state.maxWidth,"opacity":this.state.huo,"zIndex":this.state.huz}} 
        	  onInput={(event)=>{this.hourInput(event.target.value);}} 
      	      ref={this.huInput}
        	  	type="number"  min="0" max ="24" 
        	  	className="display-7 no-spin border-ridge text-center position-relative" />      	  			  		
        	  <div className="text-primary text-center interactive-box" 
        	  	onClick={(event)=>{this.hourInDEcrease(parseInt(this.props.parts.hour) - 1);} }>
      	  		<TriangleDownIcon size={ this.state.iconsize} />
      	  	</div>   	  		
      	  </div>
      	  <div className="display-7 mx-1">:</div>
      		<div className="position-relative curved-shadow">    		
    	  		<div className="text-primary text-center interactive-box" 
    	  			onClick={(event)=>{   	  				
    	  				this.minuteInDEcrease((parseInt(this.props.parts.minute) + 1)%60);} 
    	  			}>
      		  	<TriangleUpIcon size={ this.state.iconsize} />
      		  </div>   		  
        	    <input style={{"maxWidth":this.state.maxWidth,"opacity":this.state.mro,"zIndex":this.state.mrz}}  
        	      onClick={(event)=>{this.minuteClick(event);}} type="text"
        	      className="display-7 border-ridge text-center position-absolute" 
        	  	  value={this.state.clock.minute} onInput={()=>{}}/>         	 
        	    <input style={{"maxWidth":this.state.maxWidth,"opacity":this.state.muo,"zIndex":this.state.muz}} 
          	    onInput={(event)=>{this.minuteInput(event.target.value);}} 
        	      ref={this.muInput}
          	  	type="number"  min="0" max ="59" 
          	  	className="display-7 no-spin border-ridge text-center position-relative" />	    	    
      		  <div className="text-primary text-center interactive-box"
      		  	  onClick={(event)=>{
      		  	  	var value = this.props.parts.minute - 1;
      		  	  	value = (value < 0 )?59:value;
      		  	  	this.minuteInDEcrease(value);
      		  	  	} }>
      		  	 <TriangleDownIcon size={ this.state.iconsize} />
    		    </div>     		  	
      		</div>
        	<div className={ampm} onClick={(event)=>{this.periodClick(event);}} >{this.state.clock.dayPeriod}</div>
      	</div>
     );
  }
  
}