"use strict";
import React, { Component } from "react";
import DisplayScreen from "./DisplayScreen";

export default class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.textClass= "display-8 interactive-box"
    this.state={
    	cancel:"Cancel",
    	cancel_class:this.textClass+" text-primary",
    	ok:"Ok",
    	ok_class:this.textClass+" text-primary"
    }
  }
  
  componentDidMount() {
  	try{
  		var {cancel,cancel_class,ok,ok_class} = this.props.action_define();
  		this.setState({cancel:cancel,cancel_class:this.textClass+" "+cancel_class,ok:ok,ok_class:this.textClass+" "+ok_class});
  	}
  	catch(e){ 		
  	}
  }
 
  render() {
    return(
	  	<DisplayScreen key_val="confirm_dialog" display="ds-modal-content"  >
			<figure className="p-3">
			<figcaption className="mb-5" >
				<div className="display-8 text-center">{this.props.title}</div>
			</figcaption>
					<div className="d-flex justify-content-between">
						<div className={this.state.cancel_class} onClick={()=>{ this.props.on_done(false); }} >{this.state.cancel}</div>
						<div className={this.state.ok_class} onClick={()=>{ this.props.on_done(true);}} >{this.state.ok}</div>
					</div>
		 </figure>
		 </DisplayScreen>
    	)
  }
  
}