"use strict";
import React, { Component } from "react";
import ErrorBoundary from "./ErrorBoundary.js";
import {UtilClock} from "../lib/util.js";
import DatePicker from "./DatePicker.js";
import SearchTimezone from "./SearchTimezone.js";
import TimePicker from "./TimePicker.js";
import WorldClock from "./WorldClock.js";
import TZDate from "tz-date";
import { CSSTransition, TransitionGroup} from "react-transition-group";

export default class Calculator extends Component {
	
  constructor(props) {
    super(props);
    this.util_clock = new UtilClock();
    let {clock,tzdate} = this.util_clock.getClock(this.props.options,this.props.locale,TZDate.tz);
	  this.state ={timezone:TZDate.tz,clock:clock,parts:tzdate.getParts(),dates:[],dirty:false};
  }
  
  dateChanged(arg,value){
  	if(arg == "dirty" && value){
    	this.setState({dirty:true});
  	}
  	else{
      let {clock,tzdate} = this.util_clock.getClock(this.props.options,this.props.locale,this.state.timezone,value);
    	this.setState({parts:tzdate.getParts(),clock:clock,dirty:false});		
  	}
  }
  
  timeChanged(parts,reset){
  	if(reset){
      let {clock,tzdate} = this.util_clock.getClock(this.props.options,this.props.locale,this.state.timezone);
    	this.setState({parts:tzdate.getParts(),clock:clock});
  	}
  	else{
      let {clock,tzdate} = this.util_clock.getClock(this.props.options,this.props.locale,this.state.timezone,parts);
    	this.setState({parts:tzdate.getParts(),clock:clock});
  	}
  }
  
  timeZoneChanged(timezone){
  	if(this.props.isValidTimeZone(timezone)){
  		this.setState({timezone:timezone});  	
  	}
  }
  
  addDate(index){
  	var dates = [...this.state.dates]
  	var obj = {}
  	obj["parts"] = {...this.state.parts}
  	obj["timezone"] = this.state.timezone;
		var tza = this.state.timezone.split("/");
  	obj["tz"] = tza[tza.length-1]
  	dates.push(obj)
  	this.setState({dates:dates},()=>{
  		this.timeZoneChanged(TZDate.tz);
  	})
  }
  
  remove(timezone,index){
  	var date = (index == 0) ? this.state.dates[1] : this.state.dates[0]
  	if(date){
    	this.setState({dates:[{...date,parts:{...date.parts}}]})
  	}
  	else{
    	this.setState({dates:[]}) 		
  	}
  }
  
  toAlarmEdit(index){
  	var date =  this.state.dates[index] 
  	var obj = {...date,parts:{...date.parts}}
  	this.props.toAlarmEdit(null,obj)
  }
  
  render() {

  	if(this.state.dates.length == 2){
  		try{
  			var localA
  			var localB
  		var timezoneA = this.state.dates[0]["timezone"];
  		var timezoneB = this.state.dates[1]["timezone"];
  		var timezoneA_short = this.state.dates[0]["tz"];
  		var timezoneB_short = this.state.dates[1]["tz"];
  		var partsA = {...this.state.dates[0]["parts"]}
  		var partsB = {...this.state.dates[1]["parts"]}

  		
      var {clock:clockA,tzdate:tzdateA} = this.util_clock.getClock(this.props.options,this.props.locale,timezoneA,this.state.dates[0]["parts"]);
      var {clock:clockB,tzdate:tzdateB} = this.util_clock.getClock(this.props.options,this.props.locale,timezoneB,this.state.dates[1]["parts"]);
  
      var timeZonediff_AB 		= (tzdateA.getTimezoneOffset() - tzdateB.getTimezoneOffset());   
      partsA["minute"] = partsA["minute"]+timeZonediff_AB;
      var {clock:clockRTB,tzdate:tzdateRTB} = this.util_clock.getClock(this.props.options,this.props.locale,timezoneB,partsA);     
    	var relB= "Equivalent to ("+tzdateRTB.toString()+")";
      var prefix_AB = timeZonediff_AB < 0 ? "-":"+";
      timeZonediff_AB = Math.abs(timeZonediff_AB);    
      var timeZonediff_AB_str = timezoneB_short + " = " + timezoneA_short +" "+prefix_AB +""+Math.floor((timeZonediff_AB / 60)) +":"+(timeZonediff_AB % 60);
      
      var timeZonediff_BA 		= (tzdateB.getTimezoneOffset() - tzdateA.getTimezoneOffset());
      partsB["minute"] = partsB["minute"]+timeZonediff_BA;
      var {clock:clockRTA,tzdate:tzdateRTA} = this.util_clock.getClock(this.props.options,this.props.locale,timezoneA,partsB);
    	var relA= "Equivalent to ("+tzdateRTA.toString()+")";
      var prefix_BA = timeZonediff_BA < 0 ? "-":"+";
      timeZonediff_BA = Math.abs(timeZonediff_BA)     
      var timeZonediff_BA_str = timezoneA_short + " = " + timezoneB_short +" "+prefix_BA+""+Math.floor((timeZonediff_BA / 60)) +":"+(timeZonediff_BA % 60);
      
      var time_diff_milli= 	tzdateB.getTime() - tzdateA.getTime();
      var time_prefix = time_diff_milli < 0 ? "(-)":"(+)";
      
      var timediff =  Math.floor( Math.abs(time_diff_milli) / 1000);
      
      var timediff_sec =  timediff % 60
      var timediff_min =   Math.floor(timediff / 60) 
           
      var timediff_a_min  =  timediff_min % 60;
      var timediff_a_hour =   Math.floor(timediff_min / 60); 
      
      var timediff_b_hour =  timediff_a_hour % 24;
      var timediff_b_day =   Math.floor(timediff_a_hour / 24);
      
      var timediff_c_day =   timediff_b_day % 7;
      var timediff_c_week =   Math.floor(timediff_b_day / 7);
      
      
      	localA = tzdateA.toLocaleTimeString(this.props.locale, {
  				hour:"numeric",
  				minute:"numeric",
  				timeZoneName:"short",
  				timeZone:timezoneA
  			});
      	localB = tzdateB.toLocaleTimeString(this.props.locale, {
  				hour:"numeric",
  				minute:"numeric",
  				timeZoneName:"short",
  				timeZone:timezoneB
  			})
      }
      catch(error){}     
  	}

    return (	
    	<ErrorBoundary>
    	
    	{ this.state.dates.length < 2 &&
    		<div className="my-3" >
    			<div className="text-center my-3">
    				<SearchTimezone timezone={this.state.timezone}  isClicked={(timezone)=>{this.timeZoneChanged(timezone);}} isAdded={false} title="Zone"  datalist_id="time_zones"  />
    			</div>
    			<TimePicker label="Time" timezone = {this.state.timezone} options={this.props.options} locale={this.props.locale} parts={this.state.parts} stateChanged={(parts,reset)=>{this.timeChanged(parts,reset);}} />    	 	    
    			<div className="text-center my-3" > 			
    				<DatePicker parts={this.state.parts} clock={this.state.clock} setProp={(arg,value)=>{this.dateChanged(arg,value);}} editMode={true} />
    			</div>  						    		    
  	 		</div>
      	}
    	  	
			<TransitionGroup>	 
  		{this.state.dates.map((obj,index) => { 
  			return(
			<CSSTransition key={obj.timezone+"_"+index} timeout={500} classNames="area">
		  	<WorldClock  
		  	options={this.props.options} 
		  	locale={this.props.locale} 
		  	timezone={obj.timezone} 
		  	parts={obj.parts}
		  	index={index}
		  	isClicked={(timezone)=>{this.remove(timezone,index);}} />
		  </CSSTransition>
			);
  		}
  		)}
  		</TransitionGroup>	
    	  	
  		{ this.state.dates.length == 2 &&
  		<div className="my-3">
  		  <div className="display-9 d-flex flex-column" >
	    		<div className="display-8" >Time Zone Difference</div>
		  		<div  className="my-1">{timeZonediff_BA_str}</div> 
  		  	{ timeZonediff_AB != 0 && 
  		  		<React.Fragment>
		  			<div  className="my-1">{relB}</div>
		  			<div  className="my-1">{timeZonediff_AB_str}</div>
  		  		<div  className="my-1">{relA}</div>
  		  		</React.Fragment>
  		  		}
  			</div>
  		  <div className="display-9 d-flex flex-column  my-3 " >
  		    <div className="display-8" >Time Difference</div>
		  		<div className="my-1">{time_prefix} {timediff} Seconds </div>
		  		{timediff_min != 0 && <div className="my-1">{time_prefix} {timediff_min} Minutes,  {timediff_sec} Seconds</div>}
		  		{timediff_a_hour != 0  && <div className="my-1">{time_prefix} {timediff_a_hour} Hours, {timediff_a_min} Minutes,  {timediff_sec} Seconds</div>}
		  		{timediff_b_day != 0  && <div className="my-1">{time_prefix} {timediff_b_day} Days, {timediff_b_hour} Hours, {timediff_a_min} Minutes,  {timediff_sec} Seconds </div>}
		  		{timediff_c_week != 0  && <div className="my-1">{time_prefix} {timediff_c_week} Weeks, {timediff_c_day} Days, {timediff_b_hour} Hours, {timediff_a_min} Minutes,  {timediff_sec} Seconds </div>}
		  	</div>
  	 		<div className="display-9 text-primary interactive-box text-center my-4" onClick={()=>{this.toAlarmEdit(0);}} >
  	 		Add Alarm for {localA}</div>
  	 		<div className="display-9 text-primary interactive-box text-center my-4" onClick={()=>{this.toAlarmEdit(1);}} >
  	 		Add Alarm for {localB}</div>
  	 		<div>&nbsp;</div>

			</div>
  		}
  		
    	{ this.state.dates.length < 2 &&
    		<div className="my-3" > 						    		    
    			<div className="my-5" >	  
    				{this.state.dates.length == 0 && !this.state.dirty &&
    					<div className="display-7 text-primary interactive-box text-center" onClick={()=>{this.addDate(0);}} >Add Starting Date</div>
    				}
    				{this.state.dates.length == 1 && !this.state.dirty &&
    				<div className="display-7 text-primary interactive-box text-center" onClick={()=>{this.addDate(1);}} >Add Ending Date</div>
    				}
    			</div>
  	 		</div>
      	}
  		
    	</ErrorBoundary>   		
    );
  }
    
}