"use strict";
import React, { Component } from "react";
import WorldAlarm from "./WorldAlarm.js";
import { CSSTransition, TransitionGroup} from "react-transition-group";
import ErrorBoundary from "./ErrorBoundary.js";
import DisplayScreen from "./DisplayScreen";
import {UtilPermission,alarm_tone} from "../lib/utilPermission.js";

export default class AlarmList extends Component {
  constructor(props) {
    super(props);
    this.state={alarm_list:[],snooze_list:[]};
    this.util_permission = new UtilPermission(); 
  }
  
  componentDidMount() {
    window.addEventListener("world_clock_notification_dismiss",(event) => {
    	this.preRequestPause(false);
    });
    
    window.addEventListener("world_clock_notification_pause",(event) => {
    	this.preRequestPause(true);
    });
  }
  
  preRequestPause(action){
  	if(this.state.alarm_list.length > 0 ){
  		this.requestPause(action);
  	}
  }

  toAlarmEdit(alarm_key){
  	this.props.toAlarmEdit(alarm_key);
  }
    
  cleanAlarm(alarm_key){
    var alarm_list  = this.state.alarm_list.filter(key =>   key != alarm_key);
    var snooze_list = this.state.snooze_list.filter(key =>  key != alarm_key); 		
		return {"alarm_list":alarm_list,"snooze_list":snooze_list}
  }
  
  removeAlarm(alarm_key){  
  		try{
    		 clearTimeout(this.alarmTimeout);
       	 this.alarmTimeout = null;
  			 this.clearServiceWorker();
   			 this.props.removeAlarm(alarm_key);
   			 this.setState({snooze_list:[]});
     	   var evt = new CustomEvent("world_clock_notification_remove", {detail:{key: alarm_key}});
    		 window.dispatchEvent(evt);
  		}
  		catch(error){
  			console.log(error)
  		}
  }
  
  startAlarm(alarm_key){
  	try{
  		var is_playing  = this.state.alarm_list.length > 0;
  	  var {alarm_list,snooze_list} = this.cleanAlarm(alarm_key);
    	alarm_list.push(alarm_key); 		
  		clearTimeout(this.alarmTimeout);
    	this.alarmTimeout = null;
    	this.alarmTimeout = setTimeout(()=>{
      	this.setState({alarm_list:[],snooze_list:[]},()=>{
      		this.util_permission.requestPause();
        	this.props.unMountModal();		
      	});
  		},5*60000);
  		
    	this.setState({alarm_list:alarm_list},()=>{
    		var alarm = this.props.worldalarm[this.state.alarm_list[0]];
    		if(alarm && !is_playing){
  				this.util_permission.requestPlay(alarm.default_alarm_tone.src,alarm.volume);
  				this.props.mountModal(this.getAlarmObject());
      		} 			   					
  			});	
  	}
  	catch(error){
  		console.log(error)
  	}
  }
  
  requestPause(pause){
  	try{
    	this.props.unMountModal();
  		this.util_permission.requestPause();
  		var snoo_alarm = this.clearServiceWorker();
  		
  		clearTimeout(this.alarmTimeout);
    	this.alarmTimeout = null;
    	if(pause && snoo_alarm ){
   			var evt = new CustomEvent("world_clock_set_notification", {detail:{key: snoo_alarm.alarm_key,timeout:(5*60000)}});
  			window.dispatchEvent(evt);
      	this.setState({alarm_list:[],snooze_list:[...this.state.alarm_list]},()=>{
        		this.alarmTimeout = setTimeout(()=>{
          		var is_playing  = this.state.alarm_list > 0;
        	  	this.setState({alarm_list:[...this.state.snooze_list],snooze_list:[]},()=>{
        	  		if(!is_playing){
          	      this.util_permission.requestPlay(snoo_alarm.default_alarm_tone.src,snoo_alarm.volume);
          	      this.props.mountModal(this.getAlarmObject());         	  			
        	  		}  	  			
        	  	});
        		},5*60000);	 		
      	});
    	} 
    	else{
      	this.setState({alarm_list:[],snooze_list:[]});
    	}		
  	}
  	catch(error){
  		console.log(error)
  	}
  }
  
  clearServiceWorker(){
  	var alarm_return = null;
  	for (var k=0; k < this.state.alarm_list.length; k++){
  		var alarm = this.props.worldalarm[this.state.alarm_list[k]];
  		if(alarm){
  			alarm_return = alarm; 
  			var evt = new CustomEvent("world_clock_notification_remove", {detail:{key: alarm.alarm_key}});
  			window.dispatchEvent(evt);
  		}
  	}
  	for (var k=0; k < this.state.snooze_list.length; k++){
  		var alarm = this.props.worldalarm[this.state.snooze_list[k]];
  		if(alarm){
  			var evt = new CustomEvent("world_clock_notification_remove", {detail:{key: alarm.alarm_key}});
  			window.dispatchEvent(evt);
  		}
  	}
  	console.log("alarm_return ")
  	console.log(alarm_return)
  	return {...alarm_return}
  }
  
  getAlarmObject(){
		  return( 
  	  	<DisplayScreen key_val="start_alarm_alarmlist" display="ds-modal-content ds-modal-fill p-3" getChild={()=>{
  	  		var element =  this.getDisplayObject();
  	  		return element;
  	  		}} > </DisplayScreen>
		  );
  }
  
  getDisplayObject(){
  	var element = 
  		<React.Fragment>
  				<div className="d-flex flex-row justify-content-around align-items-center x-scroll h-13">
		  			<div className="display-7 text-primary interactive-box" onClick={()=>{ this.requestPause(true); }} >Snooze</div>
		  			<div className="display-7 text-primary interactive-box" onClick={()=>{ this.requestPause(false);}} >Dismiss</div>		  				
				  </div>
		  		<div className="d-flex flex-column justify-content-between align-items-center x-scroll h-83 position-relative">
		  			<div className="d-flex-row-centered">
		  				<div className="d-flex-column-centered  w-100">		
								{this.getAlarmList()}
							</div>
						</div>
					</div>
  		</React.Fragment>
			return element;
  }
 
  getAlarmList(){
  	var element = [];
    	for (var k=0 ;  k < this.state.alarm_list.length ; k++){
    		var alarm = this.props.worldalarm[this.state.alarm_list[k]];
    		if(alarm){
      		var alarmKey = alarm.alarm_key +"start_alarm";
      		element.push(
          	<WorldAlarm 
          	key={alarmKey}
          	alarm_key={alarmKey}
          	label={alarm.label}
          	parts={alarm.parts}
          	editMode={false}
          	alarmShowOnly={true}
          	options={alarm.options} 
      	  	locale={alarm.locale} 
      	  	timezone={alarm.timezone} 
          	repeat={alarm.repeat}
          	toAlarmEdit={(alarm_key)=>{}}
          	removeAlarm={()=>{}}
      	  	/>		  
      		);	
    		}
    	}  		
  	return element;
  }
  
  getElement(){
  	var element = [];
  	if(Object.keys(this.props.worldalarm).length == 0 ){
  		element.push(
  			<CSSTransition key="add_alarm_kdhtbrt" timeout={500} classNames="area">  		  
  			 <div className="d-flex-row-centered">
  			 	<div className="d-flex-column-centered text-center"> 
  			 		<div className="display-4 text-primary interactive-box text-center" onClick={()=>{this.toAlarmEdit(null);}} >Add Alarm</div>
  			 		<div className="text-info text-center" >Please make sure this application is awake to get alarm notifications</div>
  			 	</div>
  			 </div>
  		  </CSSTransition>
  		);
  	}
  	else{
    	for (var k in this.props.worldalarm){
    		var alarm = this.props.worldalarm[k];
    		element.push(
    			<CSSTransition key={alarm.alarm_key} timeout={500} classNames="area">  		  
        	<WorldAlarm 
        	alarm_key={alarm.alarm_key}
        	startAlarm={(alarm_key)=>{this.startAlarm(alarm_key);}}
        	label={alarm.label}
        	parts={alarm.parts}
        	editMode={false}
        	options={alarm.options} 
    	  	locale={alarm.locale} 
    	  	timezone={alarm.timezone} 
        	repeat={alarm.repeat}
        	toAlarmEdit={(alarm_key)=>{this.toAlarmEdit(alarm_key);}}
        	removeAlarm={(alarm_key)=>{this.removeAlarm(alarm_key);}}
    	  	/>		  
    		  </CSSTransition>	
    		);
    	}  		
  	}
  	return element;
  }
  
  render() {
    	return (	
    		<ErrorBoundary>
				<TransitionGroup>	 
    		{this.getElement()}
    		</TransitionGroup>
    		</ErrorBoundary>
     );
  }
  
}