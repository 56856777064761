"use strict";
//import "regenerator-runtime/runtime";
//import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import fav from "./favicon.ico";
import notificationimage from "./images/notification.png";
import "./scss/main.scss";
import MainComponent from "./components/MainComponent.js";

var userAgent = navigator.userAgent || navigator.vendor || window.opera;
userAgent = userAgent.toLowerCase();
window.userAgentIsEdge = /edge/i.test(userAgent);
window.userAgentIsFirefox = /firefox/i.test(userAgent);
window.userAgentIsSafari = /safari/i.test(userAgent);
window.userAgentIsChrome = /chrome/i.test(userAgent);
window.userAgentIsApple = (/iphone|ipod|mac|Macintosh/i.test(userAgent));
window.ismobileDeviceAgent = (/iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|silk|bb10|nexus|nokia/i.test(userAgent));
window.istabletDeviceAgent = (/ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle|silk|nexus|nokia/i.test(userAgent));
window.isWindowsPhone = (/windows phone/i.test(userAgent));
window.isAndroidPhone = (/android/i.test(userAgent));
window.isiOSPhone = (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream);

window.input_delay = 1200;
window.input_delay_inactive = 3500;
window.alert_area_delay = 5500;

window.default_OPTIONS = {
	weekday:"long",year:"numeric",month:"long",day:"numeric", hour:"numeric",minute:"numeric",millisec:"numeric",timeZoneName:"long",hourCycle :"h12"
	};

function checkIfDateInputSupported() {
  var input = document.createElement("input");
  input.setAttribute("type","date");
  input.setAttribute("value", "not-availd-date"); 
  return (input.value != "not-availd-date");
}
window.isInputDate = checkIfDateInputSupported();
window.page_available = false;

try{
		var main_container = document.querySelector("#main_container");
		ReactDOM.render( <MainComponent /> , main_container);			
}
catch(error){
		console.log(error);
}

