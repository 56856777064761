"use strict";
import React, { Component } from "react";
import PropTypes from 'prop-types';

class About extends Component {
	
  constructor(props) {
    super(props);
    this.state={world_clock_install_pwa:true};
  }
    
  updateNow(event) {
  	var event = new Event("world_clock_update_software");
  	window.dispatchEvent(event);
  }
  
  addHomeScreen(){
    var event = new Event("world_clock_install_pwa");
    window.dispatchEvent(event);
  }
 
  render() {
  	var btnTxt = (this.props.update_now)?"Update to version $ Now".replace("$",this.props.new_version):"Refresh App";
  		
    return (
    	<div className="jumbotron">
    		<div className="d-flex justify-content-between align-items-center display-9" >
      	<h1 className="display-6">World Clock</h1>
      	{this.props.instalPaw &&  <button id="wc_update_now" className="btn btn-primary display-9" onClick={(event)=>{this.addHomeScreen(false);}}>Install App</button> }
      	</div>
      	<hr className="my-4" />
      	<div className="d-flex justify-content-between align-items-center display-9" >
      		<div>Version: {this.props.version}</div>
      		<button id="wc_update_now" className="btn btn-primary" onClick={(event)=>{this.updateNow();}}>{btnTxt}</button>
      	</div>
    	  <hr className="my-4" />
    	  <div>World Clock is a free, add free, cross platform, cross browser, responsive, rich progressive web application with extreme low footprint and low energy impact. It runs in your device in offline mode without connection to the Internet. Only time you need Internet connection is if you try to refresh, update or install the application. A progressive web application runs safely in your browser with limited access to native device features like notifications, but has the look and feel of a native application. The Alarm functionality of this application may be partially or completely disabled due to underlying OS and browser restrictions.</div>

    	  <hr className="my-4" />
      	<div className="display-9">Stay tuned, we are working on World Clock native device applications with awesome functionalities that will be available soon.</div>
      	{window.isiOSPhone && 
      		<React.Fragment>
      	  <hr className="my-4" />
      		<div className="text-danger" >Coming soon...Alarm not available for this os.</div>
      	  </React.Fragment>
      		}
    	  <hr className="my-4" />
    	  <div>
    		  <a target="_blank" href="https://www.dynamicemr.com/worldclock">Helpful Videos</a>
    	  </div>   	
      	<hr className="my-4" />
      	<p className="display-11">
    		<a target="_blank" href="https://www.dynamicemr.com/terms">Terms & Conditions</a>
    	  </p>  
      	<p className="display-11">
    		<a target="_blank" href="https://www.dynamicemr.com/privacy">Privacy Policy</a>
    	  </p>   
      	<hr className="my-4" />
      	<p className="display-11">DynamicEMR&#169; 2020. All rights reserved.</p>
      	<p className="display-11">THE APPLICATION IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE APPLICATION OR THE USE OR OTHER DEALINGS IN THE APPLICATION.</p>
      	<hr className="my-4" />    	
    </div>
    );
  } 
}

About.propTypes = {
	update_now: PropTypes.bool,
	instalPaw: PropTypes.bool,
	new_version:PropTypes.string,
	version:PropTypes.string
};

export default About;

