"use strict";
import React, { Component } from "react";
import { CSSTransition, TransitionGroup} from "react-transition-group";
import ErrorBoundary from "./ErrorBoundary.js";

export default class DisplayScreen extends Component {
  constructor(props) {
    super(props);
    this.state={timestamp:0}
  }
  
  componentDidUpdate(prevProps, prevState){	
  	console.log("DisplayScreen did update" )
  }
  
  componentDidMount(){ 
  	if(this.props.getChild){
    	this.interval = setInterval(()=>{
    		this.setState({timestamp:new Date().date})
    	},1000)		
  	}	
  }
  
  componentWillUnmount(){	
  	clearInterval(this.interval);
  	this.interval = null;
  } 
  
  unMountModal(){
  	if(this.props.onClose){
  		this.props.onClose();
  	}
  	else{
      var evt = new CustomEvent("world_clock_un_mount_modal");
      window.dispatchEvent(evt); 		
  	}
  }
  
  onDone(){
  	try{
    	if(this.props.ondone){
    		this.props.ondone();
    	}
  	}
  	catch(error){
  		console.log(error)
  	}
  	try{
    	this.unMountModal();  		
  	}
  	catch(error){
  		console.log(error)	
  	}
  }
 
  render() {
  	var children = this.props.children;
  	if(this.props.getChild){
  		children = this.props.getChild();
  	}
    return (
    	<ErrorBoundary>
			<TransitionGroup>
			<CSSTransition key={this.props.key_val} timeout={500} classNames="area">  		  
			<div className="ds-modal">
			  <div className={this.props.display}>
				<div className="display-8 text-muted text-right interactive-box position-relative pr-1"   onClick={()=>{this.onDone()}}>
					<span aria-hidden="true">&times;</span>
				</div>
				{children}
			  </div>
  	 </div>	
  	 </CSSTransition>
  	 </TransitionGroup>    	
  	 </ErrorBoundary>
    );
  }
  
}