"use strict";
import React, { Component } from "react";
import {UtilState} from "../lib/util.js";

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.labelClass = "display-10 border-ridge";
    this.util_state =  new UtilState();
    this.dateInput = React.createRef();
  }
 
  componentDidMount() {
  	this.setDateValueEditMode()
  }
  
  componentDidUpdate(prevProps, prevState){
    var props_return =  this.util_state.stateIsChanged(prevProps.parts , this.props.parts, false);
    if(props_return){
    	this.setDateValueEditMode()
  	}	
  }
 
  dateChanged(event){
		clearTimeout(this.timeout);
		this.timeout = null;
		this.props.setProp("dirty",true);
		this.timeout = setTimeout(()=>{
			var parts = {...this.props.parts};
			var date_arr = event.target.value.split("-");
			parts.year = date_arr[0];
			parts.month = date_arr[1];
			parts.day = date_arr[2];
			this.props.setProp("parts",parts);	
		},window.input_delay); 
  }
 
  setDateValueEditMode(){
  	 if(window.isInputDate && this.props.editMode){
  	  	this.dateInput.current.value = this.getCaDate(this.props.parts)
  	 }
  }
  
  getCaDate(parts){
  	return parts.year+"-"+ this.d2(parts.month) +"-"+ this.d2(parts.day);
  }
  
  d2(n){
  	return ("0"+n).slice(-2);
  }
  
  render(){
  	  var date_picker = <span>{this.props.clock.weekday}, {this.props.clock.month}  {this.props.clock.day}, {this.props.clock.year}</span>;
  	  if(window.isInputDate && this.props.editMode){
  	  	date_picker = <input  className="true-width-11 mt-1" type="date" ref={this.dateInput}
  	  	  onInput={(event)=>{this.dateChanged(event);}} />;
  	  }
    	return (<div>{date_picker}</div>);
  }
}


