"use strict";
import React, { Component } from "react";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false,message:"" };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true,message:error.message });
    console.error(error);
  }
  
  
  refresh(){
  	window.location.reload();
  }
  
  
  render() {
    if (this.state.hasError) {
      return <div>
      					<div className="text-danger display-8" >{this.state.message}</div>
      					<div className="display-8 text-muted text-right interactive-box position-relative mt-5 pr-1" onClick={()=>{this.refresh()}} >Refresh Page</div>
      			 </div>;
    }
    return this.props.children;
  }
}