"use strict";
import React, { Component } from "react";
import ErrorBoundary from "./ErrorBoundary.js";
import MainButton from "./MainButton.js";
import AlertArea from "./AlertArea.js";
import TZDate from "tz-date";



export default class SearchTimeZone extends Component {
	
  constructor(props) {
    super(props);
    this.onmouseEnter = this.onmouseEnter.bind(this);
    this.onmouseOut = this.onmouseOut.bind(this);
    this.onInput = this.onInput.bind(this);
    this.datalistInput = React.createRef();
    this.state = {timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,error_message:false};
		if(window.isiOSPhone){
			this.hint = "Type to narrow down your options and Click on arrow to open the timezone picker to select an item."
		}
		else{
			this.hint = "Open the timezone picker to select an item"
		}
    
  }
  
  componentDidUpdate(prevProps, prevState){ 	
    if( (prevProps.timezone != this.props.timezone) &&  (prevState.timezone == this.state.timezone) ){ 	
    	this.setState({timezone:this.props.timezone});
  	} 	
  }
  
  handleClick() {
  	if(this.state.timezone){
    	this.props.isClicked(this.state.timezone,true);
  	}
  	else{
    	this.datalistInput.current.focus();
  	}
  }
  
  _onInput(event){
  	var value = event.target.value;
  	this.setState({timezone:value,tmp:value}
  	,()=>{
    	this.props.isClicked(this.state.timezone,false);
    	setTimeout(()=>{
    		if(this.props.isAdded){
    			event.target.blur();
    		} 
    	},0); 	
  	});
  }
  
  onInput(event){
  	var value = event.target.value;
  	this.setState({timezone:value,tmp:value,error_message:false}
  	,()=>{
  		try{
  			var date = new TZDate()
  			date.toLocaleTimeString(TZDate.LOCALE,{timeZone:value});
      	this.props.isClicked(this.state.timezone,false);
      	event.target.blur();
  		}
  		catch(error){
  			this.setState({error_message:error.message+". "+this.hint})
  		} 	
  	});
  }
    
  onmouseEnter(event){  	
  	this.setState(function(){
  		return {tmp:this.state.timezone, "timezone":""};
  	}); 	
  }
  
  onmouseOut(event){ 	
  	this.setState(function(){
  		return {timezone:this.state.tmp};
  	}); 		
  }
  
  render() {
    	return (	
    		<ErrorBoundary>
				<div className="input-group">
					<div className="input-group-prepend">
						<MainButton direction="d-inline" iconsize="16" isClicked={()=>{this.handleClick();}} isActive={false}  attributes={{"className":"btn btn-outline-primary"}} icon={this.props.icon} title={this.props.title} />
					</div>
					<input 
					ref={this.datalistInput}  
					value={this.state.timezone} 
					onInput={this.onInput} 
					onMouseEnter={this.onmouseEnter} 
					onMouseOut={this.onmouseOut}  
					placeholder="Type ahead" autoComplete="off" 
					type="text" list={this.props.datalist_id} className="form-control text-primary"  />
				</div>
				<AlertArea  label={this.state.error_message} isClicked={()=>{this.setState({error_message:null}) }} />
        </ErrorBoundary>
    	);
  }
  
}