"use strict";
import React, { Component } from "react";
import ErrorBoundary from "./ErrorBoundary.js";
import { CSSTransition, TransitionGroup} from "react-transition-group";
import AddTask from "./AddTask.js";
import TaskDetails from "./TaskDetails.js";
import ConfirmDialog from "./ConfirmDialog.js";
import MoveTask from "./MoveTask.js";
import Dialog from "./Dialog.js";
import { SearchIcon,PlusCircleIcon, ArrowDownIcon,ChecklistIcon,CheckIcon} from "@primer/octicons-react";
import {UtilState} from "../lib/util.js";

export default class Planner extends Component {

  constructor(props) {
    super(props);
    this.state = {task_list:[],task_type:"do_now"}
    this.util_state =  new UtilState();
    this.one_day = 24*3600*1000;
    this.task_types={do_now:"Now's",do_later:"Later's",postpone:"Postpone's",reduce:"Reduces",complete:"Completed"};
  }
  
  componentDidMount() {
  	try{
    	var clock_settings = window.localStorage.getItem("planner");
    	if(clock_settings){
    		this.setState(JSON.parse(clock_settings));
    	}   		
  	}
  	catch(error){
  		console.log(error);
  	}
  }
   
  componentDidUpdate(prevProps, prevState ){
  	try{
      var state_return =  this.util_state.stateIsChanged(this.state, prevState ,  false);
      if(state_return){
      	window.localStorage.setItem("planner",JSON.stringify(this.state));		   	
      }
  	}
  	catch(error){
  		console.log(error);
  	}
  }
  
  editTask(index){
  	try{
 		 if(index == undefined || index == null){
			 return
		 }
 		 var st = this.util_state.deapClone(this.state)
		 var task_obj = st.task_list[index];
	   this.props.mountModal(<AddTask getTask={()=>task_obj} on_done={(task,onComplete)=>{this.onAdd(task,onComplete,index);}} />) ; 			
  	}
  	catch(error){
 		 this.handleError(error);
  	}
  }
  
 addTask(){
   this.props.mountModal(<AddTask on_done={(task,onComplete)=>{this.onAdd(task,onComplete);}} />) ; 			
 }
 
 onAdd(task,onComplete,index){
 	if(task){
    try{    	
    	var task_title = task.task_title;  	
    	if(!task_title || !task_title.trim() ){
   			onComplete("Please provide a title for your task","alert-warning")  	
    	}
    	else{
     		var task_obj = this.util_state.deapClone(task); 
     		var task_type = "do_now"
     		if(!task_obj["task_urgent"] && !task_obj["task_important"]){
     			task_type = "postpone"
     		}
     		else if(!task_obj["task_urgent"] && task_obj["task_important"]){
     			task_type = "do_later"
     		}
     		else if(task_obj["task_urgent"] && !task_obj["task_important"]){
     			task_type = "reduce"
     		}
     		task_obj["task_type"] = task_type;
     		task_obj.task_title = task_obj.task_title.trim();
     		var st = this.util_state.deapClone(this.state);
   		  var task_list = st.task_list;    		
     		if(index != undefined && index != null){
       		task_list.splice(index,1,task_obj)
     		}
     		else{
       		task_obj["time_stamp"] = new Date().getTime();
       		task_list.push(task_obj);     			
     		}
     		this.setState({task_list:task_list},()=>{
     			onComplete("Saved Successfully","alert-success")    		
     		})
    	} 	
    }
    catch(error){
 			onComplete(error.message,"alert-warning")
    }

 	}
 	if(!task){
 	 	this.props.unMountModal();	
 	}
 }
 
 getTaskTypeTitle(task_type){
	 task_type = (task_type)? task_type : this.state.task_type;
	 var title = this.task_types[task_type];
	 if(!title){
		 if(task_type == "all"){
				title ="All";
		 }
		 else{
			 title= "Not Found"
		 }
	 }	 
	 return title;
 }
 
 getTitleHint(task_type){
	 task_type = (task_type)? task_type : this.state.task_type;
	 var title = "Not Found"
	 if(task_type == "do_now"){
		title ="These are your immediate tasks, try to get them done ASAP.";
	 }
	 else if(task_type == "do_later"){
			title ="These tasks are important. Move them to Now's once you have time to tackle them.";
	 }
	 else if(task_type == "postpone"){
			title ="If they stand here for long, delete them.";
	 }
	 else if(task_type == "reduce"){
		 title ="Delegate or split these tasks. Don't even think of doing them yourself.";
	 }
	 else if(task_type == "all"){
			title ="Don't worry you got this. Start with Now's, tackle Later's, delete Postpone's as much as possible, and delegate Reduce's.";
	 }
	 else if(task_type == "complete"){
			title ="Feels good to have done so much. Time to delete them.";
	 }
	 return title;
 }
 
 getOrganizationClass(type){
	   var title = ""
		 if(type == "do_now"){
			title ="text-success";
		 }
		 else if(type == "do_later"){
				title ="text-warning";
		 }
		 else if(type == "postpone"){
				title ="text-danger";
		 }
		 else if(type == "reduce"){
			 title ="text-dark";
		 }
		 else if(type == "all"){
				title ="text-primary";
		 }
		 else if(type == "complete"){
				title ="text-muted";
		 }
	   return title+" no-line-height mr-1";
 }
 
 onDeleteTask(confirm,index){
	 try{
		 if(confirm){
			 var st = this.util_state.deapClone(this.state)
			 var task_title = st.task_list[index].task_title;
			 var task_list = st.task_list
			 task_list.splice(index,1);
			 this.setState({task_list:task_list},()=>{
				 this.props.unMountModal();
			   this.props.mountModal(<Dialog title={"Deleted task \""+task_title+"\"."} on_done={()=>{this.props.unMountModal();}} />) ;		 
			 })
		 }
		 else{
			 this.props.unMountModal();
		 }
	 }
	 catch(error){
		 this.handleError(error);
	 }
 }
 
 deleteTask(index){
	 try{
		 if(index == undefined || index == null){
			 return
		 }	 
		 var task_type = this.getTaskTypeTitle(this.state.task_list[index].task_type);
		 var task_title = this.state.task_list[index].task_title;
	   this.props.mountModal(<ConfirmDialog 
	  	 	title={"Do you want to delete task \""+task_title+"\" of type \""+task_type+"\"?"} 
	   		action_define={()=>{return {"cancel":"Cancel","cancel_class":"text-primary","ok":"Delete","ok_class":"text-danger"}} }
	   		on_done={(confirm)=>{this.onDeleteTask(confirm,index);}} />) ; 				 
	 }
	 catch(error){
		 this.handleError(error);
	 }
 }
 
 onDeleteCompleted(confirm){
	 try{
		 if(confirm){
			 var st = this.util_state.deapClone(this.state)
			 var task_list = st.task_list
			 var new_task_list = task_list.filter((task) => task["task_type"] != "complete")
			 this.setState({task_list:new_task_list},()=>{
				 this.props.unMountModal();
			   this.props.mountModal(<Dialog title={"Deleted all completed tasks."} on_done={()=>{this.props.unMountModal();}} />) ;		 
			 })
		 }
		 else{
			 this.props.unMountModal();
		 }
	 }
	 catch(error){
		 this.handleError(error);
	 }
 } 
 
 deleteCompleted(){
	 try{
	   this.props.mountModal(<ConfirmDialog 
	  	 	title={"Do you want to delete ALL completed tasks?"} 
	   		action_define={()=>{return {"cancel":"Cancel","cancel_class":"text-primary","ok":"Delete All","ok_class":"text-danger"}} }
	   		on_done={(confirm)=>{this.onDeleteCompleted(confirm);}} />) ; 				 
	 }
	 catch(error){
		 this.handleError(error);
	 }
 } 
 
 onDoneTask(confirm,index){
	 try{
		 if(confirm){
			 var st = this.util_state.deapClone(this.state)
			 var task_title = st.task_list[index].task_title;
			 st.task_list[index].task_type = "complete";
			 this.setState(st,()=>{
				 var title= 
					<div className="text-center">
		  	 		<div>Well done!</div>
		  	 		<div>Completed task "{task_title}"</div>
		  	 	</div>;
				 this.props.unMountModal();
			   this.props.mountModal(<Dialog 
			  	 title={title} 
			     on_done={()=>{this.props.unMountModal();}}></Dialog>);		 
			 })
		 }
		 else{
			 this.props.unMountModal();
		 }
	 }
	 catch(error){
		 this.handleError(error);
	 }
 }
 
 completeTask(index){
	 try{
		 if(index == undefined || index == null){
			 return
		 }	 
		 var title = this.getTaskTypeTitle(this.state.task_list[index].task_type);
		 var task_title = this.state.task_list[index].task_title;
	   this.props.mountModal(<ConfirmDialog 
	  	 title={"Is task \""+task_title+"\" completed?"} 
  		 action_define={()=>{return {"cancel":"No","cancel_class":"text-primary","ok":"Yes","ok_class":"text-success"}}}
	     on_done={(confirm)=>{this.onDoneTask(confirm,index);}} />) ; 				 
	 }
	 catch(error){
		 this.handleError(error);
	 }
} 
 
 onMoveTask(index,to_task){
	 try{
		 if(index == undefined || index == null  || !to_task){
			 this.props.unMountModal();
		   this.props.mountModal(<Dialog title={"Can not move task with id \""+index+"\" to \""+to_task+"\"."} on_done={()=>{this.props.unMountModal();}} />) ;		 	 
		 }
		 else{
			 this.moveTask(index,to_task);
		 }		 
	 }
	 catch(error){
		 this.handleError(error);
	 }
 }
 
 moveTask(index,to_task){
	 try{
		 if(index == undefined || index == null ){
			 return;
		 }
		 var st = this.util_state.deapClone(this.state)
		 var task_list = st.task_list
		 var task_obj = task_list[index];
		 var task_title = task_obj.task_title;
		 if(!to_task){
			 var moves = {...this.task_types};
			 delete moves[task_obj["task_type"]];
			 var obj = {moves:moves,task_title:task_title};			 
		   this.props.mountModal(<MoveTask 
	  		 getMoveTo ={()=>{ return obj;}}
		     on_done={(task_type)=>{this.onMoveTask(index,task_type);}} />) ;  
		 }
		 else{
			 var task_type_title  = this.getTaskTypeTitle(to_task)
			 task_obj["task_type"] = to_task;
			 if(to_task == "do_now"){			 
				 task_obj["task_urgent"] = true;
				 task_obj["task_important"] = true;
			 }
			 else if(to_task == "do_later"){
				 task_obj["task_urgent"] = false;
				 task_obj["task_important"] = true;
	   	 }
	   	 else if(to_task == "reduce"){
	 			 task_obj["task_urgent"] = true;
				 task_obj["task_important"] = false;
	   		}
	   	 else if(to_task == "postpone"){
	   		 task_obj["task_urgent"] = false;
	   		 task_obj["task_important"] = false;
	   	 }
			 this.setState(st,()=>{
				 this.props.unMountModal();
			   this.props.mountModal(<Dialog title={"Moved task \""+task_title+"\" to \""+task_type_title+"\"."} on_done={()=>{this.props.unMountModal();}} />) ;		 
			 })		 
		 }
	 }
	 catch(error){
		 this.handleError(error);
	 } 
 }
 
 onHandleTask(task,onComplete,index){
	 try{
		 if (task == "close" ){
			 this.props.unMountModal();
		 }
		 else if (task == "edit" ){
			 this.props.unMountModal();
			 this.editTask(index)
		 } 
		 else if(task == "delete"){
			 this.props.unMountModal();
			 this.deleteTask(index)		 
		 }
		 else if(task == "complete"){
			 this.props.unMountModal();
			 this.completeTask(index)
		 }
		 else if(task == "move"){
			 this.props.unMountModal();
			 this.moveTask(index)			 
		 }
		 else if(typeof task == "object"){
			 this.onAdd({...task},onComplete,index)
		 }
	 }
	 catch(error){
		 this.handleError(error)	 
	 }
 }
 
 handleTask(index){
 	try{
		 if(index == undefined || index == null){
			 return
		 }
 		 var st = this.util_state.deapClone(this.state)
 		 var task_obj = st.task_list[index];
		 task_obj["task_type_title"] = this.getTaskTypeTitle(task_obj.task_type);
	   this.props.mountModal(<TaskDetails getTask={()=>{return task_obj}} on_done={(task,onComplete)=>{this.onHandleTask(task,onComplete,index);}} />) ; 			
 	}
 	catch(error){
 		this.handleError(error);
 	} 
}
 
 handleError(error){
	 console.log(error);
	 this.props.unMountModal();
   this.props.mountModal(<Dialog title={error.message} on_done={()=>{this.props.unMountModal();}} />) ;
 }
 
 getContent(){
	  var day_behind = {};	  	 
	  var element =
	  	<div key="task_list_content_hftrg">
	    <div className="d-flex justify-content-center align-items-center ">
	    	<div className={this.getOrganizationClass(this.state.task_type)} ><ChecklistIcon size={16} verticalAlign="baseline" /></div>
	    	<div className="display-8 ml-1">{this.getTaskTypeTitle()}</div>
	    </div>
	    <div className="text-info text-center">{this.getTitleHint()}</div>
	    <div className="text-center"><ArrowDownIcon size={24} /></div>
	    {
	  	this.state.task_list.map((task, index)=>{  		
	  	  var elem = null;
	  		var _return = task.task_type == this.state.task_type || this.state.task_type == "all" 
	  		if(_return){	
		  		var _behind_n = Math.floor(((new Date().getTime()) - parseInt(task.time_stamp)) / this.one_day)
	  			elem = 
	  			<div key={task.time_stamp}>	
	  			  {
	  			  	day_behind[_behind_n] != _behind_n &&
	  			  	<React.Fragment>
	  			    	<div className="text-center text-attention">{_behind_n} Day(s) { this.state.task_type != "complete" && "Behind"} { this.state.task_type == "complete" && "Ago"}</div>
	  			    	<div className="text-center"><ArrowDownIcon size={24} /></div>
	  			    </React.Fragment>
	  			  }
	  				<div className="text-primary d-flex justify-content-center align-items-center"  >
		  				{ 
		  					(this.state.task_type == "all" ||  this.state.task_type == "complete") && 
		  					<span onClick={()=>{this.handleTask(index)}} className={this.getOrganizationClass(task.task_type)+" interactive-box "} >
		  						<ChecklistIcon size={16} verticalAlign="baseline"></ChecklistIcon>
		  					</span>
		  				}
	  	    		<span className="interactive-box" onClick={()=>{this.handleTask(index)}} >{task.task_title}</span>
		  	    	{
		  	    		task.task_type == "do_later" &&
		  	    		<span className="interactive-box ml-1" onClick={()=>{this.moveTask(index,"do_now")}} >(Move to Now's)</span>
		  	    	}
		  	    	{
		  	    		(task.task_type == "postpone" || task.task_type == "complete") &&
		  	    		<span className="text-danger interactive-box ml-1" onClick={()=>{this.deleteTask(index)}} >(delete)</span>
		  	    	}
		  	    	{
		  	    		task.task_type == "do_now" &&
		  	    		<span className="text-success interactive-box ml-1" onClick={()=>{this.completeTask(index)}} >(complete)</span>
		  	    	}
		  	    	{
		  	    		task.task_type == "reduce" &&
		  	    		<span className="text-dark interactive-box ml-1" onClick={()=>{this.editTask(index)}} >(edit)</span>
		  	    	}
	  	    	</div>
	  		    <div className="text-center"><ArrowDownIcon size={24} /></div>
	  	    </div>;
		  		day_behind[_behind_n] = _behind_n;
	  		}
	  	  return elem;
	  	})
	    }
	    <div className="text-center mb-4 display-8 text-primary"  >
	    	<span className="interactive-box" onClick={()=>{this.addTask();}}>Add Task</span>
	    </div>
	    <div>&nbsp;</div>
	  	</div>  	
	  	return element;
 }
  
	getHeader(){
  	var element =     	
  	<nav key="task_list_nav_kdtenil" className="navbar navbar-expand-md navbar-light bg-light">
    <div className="navbar-brand text-primary interactive-box no-line-height"  onClick={()=>{this.addTask();}}><PlusCircleIcon size={32} verticalAlign="baseline" /></div> 
    <div className="navbar-brand text-primary interactive-box"  onClick={()=>{this.setState({task_type:"do_now"})}} >Now's</div> 
    <div className="navbar-brand text-primary interactive-box"  onClick={()=>{this.setState({task_type:"do_later"})}} >Later's</div> 
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#planner_content_header" aria-controls="planner_content_header" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" ></span>
    </button>

    <div className="collapse navbar-collapse" id="planner_content_header">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item mr-3">
      	<span className="text-primary interactive-box" onClick={()=>{this.setState({task_type:"postpone"})}} >Postpone's</span>
        </li>
        <li className="nav-item mr-3">
    		<span className="text-primary interactive-box"  onClick={()=>{this.setState({task_type:"reduce"})}} >Reduce's</span>
        </li>
        <li className="nav-item mr-3">
    		<span className="text-primary interactive-box"  onClick={()=>{this.setState({task_type:"all"})}} >All</span>
        </li>
        <li className="nav-item mr-3">
    		<span className="text-primary interactive-box"  onClick={()=>{this.setState({task_type:"complete"})}} >Completed</span>
        </li>
      </ul>
  		<hr className="res-hr" />
  		<span className="text-danger interactive-box" onClick={()=>{this.deleteCompleted()}} >Delete Completed</span>
  		<hr className="res-hr" />
    </div>
  </nav>

  return element;
  }
  
  getElement(){
  	var element = []
  	if(this.state.task_list.length == 0){
  		element.push(
  			 <div key="task_list_add_urnfgspm" className="d-flex-row-centered">
  			 	<div className="d-flex-column-centered text-center"> 
  			 		<div className="display-4 text-primary interactive-box text-center" onClick={()=>{this.addTask();}} >Add Task</div>
  			 	</div>
  			 </div>
  		  )
  	}
  	else{
  		element.push(this.getHeader());
  		element.push(this.getContent())
  	}
  	return element;
  }

 
  render() {	
    	return (	
    		<ErrorBoundary>
    		{this.getElement()}
    		</ErrorBoundary>
    	);
  }
  
}