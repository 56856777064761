"use strict";
import React, { Component } from "react";

export default class InputAction extends Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
  }
 
  setProp(arg, value){
  		this.props.setProp(arg,value);
  }
  
  render() {
  	var input_elem = <span>{this.props.label}</span> ;
  	if(this.props.editMode){
  		input_elem = <input  className="display-10 border-ridge true-width-11" 
  			value={this.props.label} onInput={(event)=>{this.setProp("label",event.target.value);}} />;
  	}
    return (<div>{input_elem}</div>);
  }
  
}