"use strict";
import React, { Component } from "react";
import {UtilState} from "../lib/util.js";
import {UtilPermission,alarm_tone} from "../lib/utilPermission.js";
import AlarmTone from "./AlarmTone";
import TZDate from "tz-date";
import AlertArea from "./AlertArea.js";

export default class ClockSetting extends Component {
  constructor(props) {
    super(props);
  	var options={...window.default_OPTIONS};
    this.state = {
    	show_seconds:false,
    	world_interval:60000,
			locale:window.navigator.language,
			options:options,
			default_alarm_tone:alarm_tone[Object.keys(alarm_tone)[0]],
			error_message:null
    };
    this.onmouseEnter = this.onmouseEnter.bind(this);
    this.onmouseOut = this.onmouseOut.bind(this);
    this.util_state =  new UtilState();
    this.util_permission = new UtilPermission();  
  }
  
  componentDidMount() {
  	try{
    	var clock_settings = window.localStorage.getItem("clock_settings");
    	if(clock_settings){
    		this.setState(JSON.parse(clock_settings));
    	}   		
  	}
  	catch(error){
  		console.log(error);
  	}
  }

  componentDidUpdate(prevProps, prevState ){
  	try{
      var state_return =  this.util_state.stateIsChanged(prevState , this.state, false);
      if(state_return){
      	window.localStorage.setItem("clock_settings",JSON.stringify(this.state));		   	
      }
  	}
  	catch(error){
  		console.log(error);
  	}
  }

  setDefaultAlarmTone(){
    this.props.mountModal(<AlarmTone default_alarm_tone={()=>this.state.default_alarm_tone} on_done={(at)=>{this.onDone(at);}} />); 			
  }
  
  onDone(at){
  	if(at){
  		this.setState({default_alarm_tone:{...at}},()=>{this.props.didChange("clock_settings");});
  	}
  	this.props.unMountModal();	
  }
  
  handleChange(event){
  	try{
    	var options = {...this.state.options};
    	var tmp = {show_seconds:false,world_interval:60000,options:options};
  	  delete options.second;
    	if(event.target.checked){
      	options.second="numeric";
      	tmp = {show_seconds:true,world_interval:1000, options:options};
    	}
      this.setState(tmp,()=>{
    		this.props.didChange("clock_settings");
    	});  		
  	}
  	catch(error){
  		console.log(error)
  	}
  }
  
  handleChange12(event){
  	var options = {...this.state.options};
  	options.hourCycle = event.target.checked?"h12":"h23";
    this.setState({options:options},()=>{
  		this.props.didChange("clock_settings");
  	});
  }
  
  handleTimeZoneName(){
  	var options = {...this.state.options};	
  	options.timeZoneName =  document.querySelector("input[name=\"timezonename_clock\"]:checked").value;
    this.setState({options:options},()=>{
  		this.props.didChange("clock_settings");
  	});
  }
  
  onInput(event){
  	this.setState({locale:event.target.value,tmp_locale:event.target.value,error_message:false}
  	,()=>{
  		clearTimeout(this.timeout);
  		this.timeout = null;
  		this.timeout = setTimeout(()=>{
    		try{
    			var date = new TZDate();
    			date.toLocaleTimeString(this.state.locale);
      		this.props.didChange("clock_settings");			
    		}
    		catch(error){
    			this.setState({error_message:error.message})
    		}
  		},window.input_delay);
  	});
  }
  
  onmouseEnter(){  	
  	this.setState(function(){
  		return {tmp_locale:this.state.locale, "locale":""};
  	}); 	
  }
  
  onmouseOut(){ 	
  	this.setState(function(){
  		return {locale:this.state.tmp_locale};
  	}); 		
  }
  
  requestPermission(){
			this.util_permission.requestPermission(
				(     )=>{
					this.props.setProp("sound_permission","allowed");
					},			
				(error)=>{
					this.props.setProp("sound_permission","failed");
					}			
			);
  }
  
  requestNotificationPermission(){	
	    if ("Notification" in window) {
	      if(this.checkBrowserNotificationPromise()) {
	        Notification.requestPermission()
	        .then((permission) => {
	        	this.doneNotification(permission);
	        });
	      } else {
	        Notification.requestPermission((permission) => {
	        	this.doneNotification(permission);
	        });
	      }
	    }
  }
  
  checkBrowserNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch(e) {
      return false;
    }
    return true;
  }
  
  doneNotification(permission){
    if(!("permission" in Notification)) {
      Notification.permission = permission;
    }
		this.props.setProp("notification_permission",permission);
  }
  
  render() {
    	return (	
    		<div>   		
    			<div className="custom-control custom-switch">
    				<input checked={!!this.state.show_seconds} type="checkbox" className="custom-control-input" id="show_seconds" onChange={(event)=>{this.handleChange(event)}} />
    				<label className="custom-control-label" htmlFor="show_seconds">show seconds</label>
    			</div>  			
    			<div className="custom-control custom-switch mt-3 mt-lg-4">
  					<input checked={this.state.options.hourCycle == "h12"} type="checkbox" className="custom-control-input" id="hour12" onChange={(event)=>{this.handleChange12(event)}} />
  					<label className="custom-control-label" htmlFor="hour12">12hour period</label>
  				</div> 		
  				<div className="mt-3  mt-lg-4" >
  					<label className="form-check-label align-top mr-1" htmlFor="inlineRadio2">Timezone:</label>
  					<div className="form-check form-check-inline">
  						<input checked={this.state.options.timeZoneName == "long" } className="form-check-input" type="radio" name="timezonename_clock" id="timezonenamelong" value="long" 
  							onChange={(event)=>{this.handleTimeZoneName(event)}} />
  						<label className="form-check-label" htmlFor="timezonenamelong">Long</label>
  					</div>
  					<div className="form-check form-check-inline">
  						<input checked={this.state.options.timeZoneName == "short"} className="form-check-input" type="radio" name="timezonename_clock" id="timezonenameshort" value="short" 
  							onChange={(event)=>{this.handleTimeZoneName(event)}} />
  						<label className="form-check-label" htmlFor="timezonenameshort">Short</label>
  					</div>
  				</div>				
  				<div className="mt-3  mt-lg-4" >
						<label className="form-check-label align-top mr-1" >Locale:</label>
						<input 
						value={this.state.locale} 
						onInput={(event)=>{this.onInput(event)}} 
						onMouseEnter={this.onmouseEnter} 
						onMouseOut={this.onmouseOut} 
						ref={this.datalistInput}  placeholder="Type ahead" autoComplete="off" type="text" list="lang_locales" className="form-control text-primary"  />
					</div>
	        <AlertArea  label={this.state.error_message} isClicked={()=>{this.setState({error_message:null}) }} />
					<figure id="main_alarm" className="main-alarm mt-3 mt-lg-4">
					  	<figcaption>
							   { (this.props.sound_permission != "allowed" && this.props.sound_permission != "failed") && <div className="display-8 text-primary interactive-box" onClick={()=>{ this.requestPermission(true); }} >Grant Alarm Permission</div>}
							   { (this.props.sound_permission == "failed") && <div className="text-warning">Please click the media player play button to enable the alarm tone</div>}
							   { (this.props.sound_permission == "allowed")&& <div className="text-info" >Alarm tone is enabled, please make sure this application is awake to get alarm notifications.</div>}
					  	</figcaption>
					  <div className="d-flex my-3" >
			  		 <div className="text-primary interactive-box align-self-center mr-3 text-nowrap" onClick={()=>{this.setDefaultAlarmTone();}} >{this.state.default_alarm_tone.name}</div>
						 	<audio className="flex-grow-1 w-100" id="main_alarm_player" preload="auto" controls loop>
						 		<source id="main_alarm_player_src" src={this.state.default_alarm_tone.src} type="audio/mpeg" />
						 	</audio>
					  </div>
					</figure>
					{
						"Notification" in window && this.props.notification_permission != "granted" &&  <div className="display-8 text-primary interactive-box" onClick={()=>{ this.requestNotificationPermission(true); }} >Grant Alarm Notification Permission</div>
					}
					{
						"Notification" in window && this.props.notification_permission == "denied" &&  <div className="text-danger" >You denied notification permission. We can not send you alarm notifications unless you allow notifications from your browser settings.</div>
					}
					
    	</div>
    		
     );
  }
  
}